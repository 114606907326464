import { createSlice, createEntityAdapter, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { chatApi } from '../../services/chatApi/chatApi';
import { RootState } from '../store';
import { Service } from '../service/serviceSlice';
import { threadApi } from '../../services/threadApi/threadApi';
import { resetStep, Steps } from '../onboarding/onboardingSlice';
import { onboardingMessagesDE, onboardingMessagesEN } from '../../../mocks/mock.onboarding';
import { Response } from '../../utils/types';

const roles = ['ai', 'human'] as const;

export type Role = (typeof roles)[number];

export type Message = {
  id: number;
  messageId?: string;
  role: Role;
  content: string;
  threadId: string;
  timestamp: string;
  isOnboardingQuestion?: boolean;
  userId?: string;
};

const messagesAdapter = createEntityAdapter<Message>({
  sortComparer: (a, b) => a.id - b.id,
});

type ChatState = {
  isLoadingMessage: boolean;
  isFetchingHistory: boolean;
} & ReturnType<typeof messagesAdapter.getInitialState>;

const initialState: ChatState = {
  ...messagesAdapter.getInitialState(),
  isLoadingMessage: false,
  isFetchingHistory: false,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<Omit<Message, 'id'> & { service?: Service }>) => {
      messagesAdapter.addOne(state, { ...action.payload, id: state.ids.length + 1 });
    },
    addManyMessages: (state, action: PayloadAction<(Omit<Message, 'id'> & { service?: Service })[]>) => {
      const messagesWithIds = action.payload.map((message, index) => ({
        ...message,
        id: state.ids.length + 1 + index,
      }));
      messagesAdapter.addMany(state, messagesWithIds);
    },
    clearMessages: (state) => {
      messagesAdapter.removeAll(state);
    },
    setLoadingMessage: (state, action: PayloadAction<boolean>) => {
      state.isLoadingMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStep, (state) => {
      const demoThreadId = state.ids.find((id) => {
        const message = state.entities[id];
        return message && message.content.startsWith('DEMO:');
      });

      if (demoThreadId) {
        const remainingMessages = state.ids.filter(
          (id) => state.entities[id]?.threadId !== state.entities[demoThreadId]?.threadId,
        );
        messagesAdapter.setAll(
          state,
          remainingMessages.map((id) => state.entities[id]!),
        );
      }
    });
    builder.addMatcher(chatApi.endpoints.sendMessage.matchPending, (state) => {
      state.isLoadingMessage = true;
    });
    builder.addMatcher(
      chatApi.endpoints.sendMessage.matchFulfilled,
      (state, action: PayloadAction<Response<Message[]>>) => {
        state.isLoadingMessage = false;
        const { meta } = action;
        const isOnboarding = meta?.arg?.originalArgs?.meta?.isOnboarding;
        const lang = meta?.arg?.originalArgs?.meta?.currentLanguage;
        const onboardingStep: Steps = meta?.arg?.originalArgs?.meta?.nextStepIndex;

        const messagesData: Message[] =
          isOnboarding && onboardingStep
            ? (lang === 'de-DE' ? onboardingMessagesDE : onboardingMessagesEN)[onboardingStep].messages.content.map(
                (mes) => ({
                  ...mes,
                  threadId: action.payload.threadId,
                  isOnboardingQuestion: true,
                }),
              )
            : action.payload.data;

        if (action.payload.threadId) {
          const remainingMessages = state.ids.filter((id) => {
            const message = state.entities[id];
            return message?.threadId !== action.payload.threadId && message?.threadId.length !== 0;
          });
          messagesAdapter.setAll(
            state,
            remainingMessages.map((id) => state.entities[id]!),
          );
          messagesAdapter.addMany(state, messagesData);
        }
      },
    );

    builder.addMatcher(chatApi.endpoints.sendMessage.matchRejected, (state) => {
      state.isLoadingMessage = false;
    });
    builder.addMatcher(
      threadApi.endpoints.fetchMessagesByThreadId.matchFulfilled,
      (state, action: PayloadAction<Message[]>) => {
        const fetchedThreadId = action.meta?.arg?.originalArgs?.threadId;
        const lang = action.meta?.arg?.originalArgs?.meta?.currentLanguage || 'en';
        const actualResponseLength = action.payload.length / 2;

        const onboardingStepKey = `step-${actualResponseLength}`;
        const onboardingContent =
          lang === 'de-DE'
            ? onboardingMessagesDE[onboardingStepKey]?.messages?.content
            : onboardingMessagesEN[onboardingStepKey]?.messages?.content;

        if (action.payload.length > 0 && action.payload[0]?.content.startsWith('DEMO')) {
          const onboardingSubset = onboardingContent.map((message, index) => ({
            ...message,
            threadId: fetchedThreadId,
            isOnboardingQuestion: true,
          }));

          messagesAdapter.upsertMany(state, onboardingSubset);
          return;
        }

        if (action.payload) {
          const remainingMessages = state.ids.filter((id) => state.entities[id]?.threadId !== fetchedThreadId);
          messagesAdapter.setAll(
            state,
            remainingMessages.map((id) => state.entities[id]!),
          );
          messagesAdapter.upsertMany(state, action.payload);
        }
        state.isFetchingHistory = false;
      },
    );
    builder.addMatcher(threadApi.endpoints.fetchMessagesByThreadId.matchPending, (state, action) => {
      const threadId = action.meta?.arg?.originalArgs?.threadId;
      const hasMessages = state.ids.some((id) => state.entities[id]?.threadId === threadId);
      if (!hasMessages) {
        state.isFetchingHistory = true;
      }
    });
    builder.addMatcher(threadApi.endpoints.fetchMessagesByThreadId.matchRejected, (state) => {
      state.isFetchingHistory = false;
    });
  },
});

export const { addMessage, addManyMessages, clearMessages, setLoadingMessage } = chatSlice.actions;

export default chatSlice.reducer;

const {
  selectAll: getAllMessages,
  selectById: getMessageById,
  selectEntities: getMessageEntities,
  selectTotal: getTotalMessages,
} = messagesAdapter.getSelectors((state: RootState) => state.chat);

export const selectMessageById = (messageId: number) =>
  createSelector(
    (state: RootState) => state,
    (state) => getMessageById(state, messageId),
  );

export const selectAllMessages = createSelector(
  (state: RootState) => state,
  (state) => getAllMessages(state),
);

export const selectMessageEntities = createSelector(
  (state: RootState) => state,
  (state) => getMessageEntities(state),
);

export const selectTotalMessageCount = createSelector(
  (state: RootState) => state,
  (state) => getTotalMessages(state),
);

export const selectMessagesByRole = (role: Role) =>
  createSelector(selectAllMessages, (messages) => messages.filter((message) => message.role === role));

export const selectLastUserMessageBefore = (messageId: number) =>
  createSelector(
    (state: RootState) => getAllMessages(state),
    (messages) => {
      const currentIndex = messages.findIndex((message) => message.id === messageId);
      if (currentIndex === -1) {
        return null;
      }

      for (let i = currentIndex - 1; i >= 0; i--) {
        if (messages[i].role === 'human') {
          return messages[i];
        }
      }
      return null;
    },
  );

export const selectMessagesByThreadId = (threadId: string) =>
  createSelector(
    (state: RootState) => getAllMessages(state),
    (messages) =>
      messages
        .filter((message) => message.threadId === threadId)
        .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()),
  );

export const selectIsLoadingMessage = (state: RootState) => state.chat.isLoadingMessage;

export const selectIsFetchingHistory = (state: RootState) => state.chat.isFetchingHistory;
