import Button from '@rio-cloud/rio-uikit/Button';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import ChatThreadCategory from '../../molecule/ChatThreadCategory/ChatThreadCategory';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { getUserProfile } from '../../../configuration/login/loginSlice';
import { useFetchThreadsQuery } from '../../../services/threadApi/threadApi';
import {
  selectCategorizedThreads,
  ChatThread,
  addNewThread,
  selectActiveThreadId,
  selectIsHistoryOfThreadsFetching,
} from '../../../store/thread/threadSlice';
import { getUserId } from '../../../utils/getUserId';
import { selectIsLoadingMessage } from '../../../store/chat/chat.slice';
import SearchField from '../../atom/SearchField/SearchField';
import { useEffect, useState } from 'react';

const texts = {
  addNewChat: 'chatPage.chatThreadList.threadDeletion.addNewChat.label',
  today: 'chatPage.chatThreadList.today.label',
  last7Days: 'chatPage.chatThreadList.last7Days.label',
  last30Days: 'chatPage.chatThreadList.last30Days.label',
  older: 'chatPage.chatThreadList.older.label',
  noMessage: 'chatPage.chatThreadList.error.noThreads',
} as const;

const ChatHistoryList = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(getUserProfile);
  const userId = getUserId(user?.sub ?? '');
  const threadId = useSelector(selectActiveThreadId);
  const { data } = useFetchThreadsQuery(
    { userId: getUserId(user?.sub ?? ''), threadId },
    { refetchOnMountOrArgChange: true, skip: false },
  );
  const isSendingInProgress = useSelector(selectIsLoadingMessage);
  const threadsInitialList = useSelector(selectCategorizedThreads);
  const isThreadsLoading = useSelector(selectIsHistoryOfThreadsFetching);

  const [threadsList, setThreadsList] = useState(threadsInitialList);

  const hasValidThreads = (threads: ChatThread[]) => {
    return threads && threads.length > 0 && threads.some((thread) => thread.title && thread.title.trim() !== '');
  };

  const handleOnClick = () => dispatch(addNewThread());

  const areAllThreadCategoriesEmpty =
    threadsList &&
    !(
      hasValidThreads(threadsList.today) ||
      hasValidThreads(threadsList.last7Days) ||
      hasValidThreads(threadsList.last30Days) ||
      hasValidThreads(threadsList.older)
    );

  const handleSearch = (searchValue: string) => {
    const filteredLists = {
      today: threadsInitialList.today.filter((item) =>
        (item.title as string).toLowerCase().includes(searchValue.toLowerCase()),
      ),
      last7Days: threadsInitialList.last7Days.filter((item) =>
        (item.title as string).toLowerCase().includes(searchValue.toLowerCase()),
      ),
      last30Days: threadsInitialList.last30Days.filter((item) =>
        (item.title as string).toLowerCase().includes(searchValue.toLowerCase()),
      ),
      older: threadsInitialList.older.filter((item) =>
        (item.title as string).toLowerCase().includes(searchValue.toLowerCase()),
      ),
    };
    setThreadsList(filteredLists);
  };

  useEffect(() => {
    setThreadsList(threadsInitialList);
  }, [threadsInitialList]);

  return (
    <div>
      <div className="padding-x-10 display-flex flex-column justify-content-start height-100pct">
        <div className="padding-bottom-20">
          <div className="display-flex justify-content-start margin-top-20 margin-x-10">
            <Button
              bsStyle="primary"
              iconName="rioglyph rioglyph-plus-light"
              disabled={isSendingInProgress}
              onClick={handleOnClick}
            >
              <FormattedMessage id={texts.addNewChat} />
            </Button>
          </div>
          <div className="margin-x-10 padding-top-20">
            <SearchField onSearch={handleSearch} />
          </div>
          {isThreadsLoading && areAllThreadCategoriesEmpty ? (
            <div className="padding-20">
              <Spinner />
            </div>
          ) : areAllThreadCategoriesEmpty ? (
            <div className="padding-20 text-center">{intl.formatMessage({ id: texts.noMessage })}</div>
          ) : (
            <div className="padding-10">
              {hasValidThreads(threadsList.today) && (
                <ChatThreadCategory title={texts.today} threads={threadsList.today} />
              )}
              {hasValidThreads(threadsList.last7Days) && (
                <ChatThreadCategory title={texts.last7Days} threads={threadsList.last7Days} />
              )}
              {hasValidThreads(threadsList.last30Days) && (
                <ChatThreadCategory title={texts.last30Days} threads={threadsList.last30Days} />
              )}
              {hasValidThreads(threadsList.older) && (
                <ChatThreadCategory title={texts.older} threads={threadsList.older} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatHistoryList;
