import { useSelector } from 'react-redux';
import { getIdToken } from '../configuration/tokenHandling/tokenSlice';

export const useProtectedAccess = () => {
  const idToken = useSelector(getIdToken);
  const accessAccountList = import.meta.env.VITE_ACCESS_ACCOUNT_LIST?.split(',');

  if (idToken) {
    return JSON.parse(accessAccountList).includes(idToken.account);
  }
  return false;
};
