import { ChatThread } from '../../store/thread/threadSlice';

export type CategorizedThreads = {
  today: ChatThread[];
  last7Days: ChatThread[];
  last30Days: ChatThread[];
  older: ChatThread[];
};

export const categorizeThreadsByDate = (threads: ChatThread[]) => {
  const today = new Date();
  const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const startOf7DaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  const startOf30DaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);

  const categorizedThreads: CategorizedThreads = {
    today: [],
    last7Days: [],
    last30Days: [],
    older: [],
  };

  threads.forEach((thread) => {
    const threadDate = new Date(thread.timestamp);

    if (threadDate >= startOfToday) {
      categorizedThreads.today.push(thread);
    } else if (threadDate >= startOf7DaysAgo) {
      categorizedThreads.last7Days.push(thread);
    } else if (threadDate >= startOf30DaysAgo) {
      categorizedThreads.last30Days.push(thread);
    } else {
      categorizedThreads.older.push(thread);
    }
  });

  const sortByTimestampDesc = (a: ChatThread, b: ChatThread) =>
    new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();

  categorizedThreads.today.sort(sortByTimestampDesc);
  categorizedThreads.last7Days.sort(sortByTimestampDesc);
  categorizedThreads.last30Days.sort(sortByTimestampDesc);
  categorizedThreads.older.sort(sortByTimestampDesc);

  return categorizedThreads;
};
