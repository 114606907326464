import Button from '@rio-cloud/rio-uikit/components/button/Button';
import InfoDialog from '@rio-cloud/rio-uikit/components/dialog/InfoDialog';
import { FormattedMessage } from 'react-intl';

export const ErrorMessage = ({
  show,
  handleClose,
  message,
}: {
  show: boolean;
  handleClose: () => void;
  message: string;
}) => (
  <InfoDialog
    show={show}
    onClose={handleClose}
    showCloseButton
    content={
      <div className="text-center">
        <span className="rioglyph rioglyph-error-sign text-color-danger text-size-h1" />
        <div className="margin-bottom-25">
          <h2>
            <FormattedMessage id="common.error.label" />
          </h2>
          <p>{message}</p>
          <p>
            <FormattedMessage id="common.contactAdmin.info" />
          </p>
        </div>
        <Button className="margin-bottom-20 " onClick={handleClose}>
          Ok
        </Button>
      </div>
    }
  />
);
