import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { addMessage, Message } from '../chat/chat.slice';

const services = ['training', 'perform', 'docs'] as const;

export type Service = (typeof services)[number];

export type ServiceState = {
  currentService: Service;
};

const initialState: ServiceState = {
  currentService: 'perform',
};

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setCurrentService: (state, action: PayloadAction<Service>) => {
      state.currentService = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addMessage, (state, action: PayloadAction<Omit<Message, 'id'> & { service?: Service }>) => {
      if (action.payload.service) {
        state.currentService = action.payload.service;
      }
    });
  },
});

export const { setCurrentService } = serviceSlice.actions;

export default serviceSlice.reducer;

export const selectCurrentService = createSelector(
  (state: RootState) => state.service.currentService,
  (currentService) => currentService,
);
