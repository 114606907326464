export const releaseNotesData = [
  {
    version: '1.0.0',
    notes: `
- First version of the application following the introduction of versioning.
- Users have access to a chat feature where they can inquire about two services: MAN Training and Perform.
        `,
    date: '2023-10-25',
    image: 'assets/images/screenshot.png',
  },
];
