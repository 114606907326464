import ListMenu from '@rio-cloud/rio-uikit/components/listMenu/ListMenu';
import { ListMenuItem } from '@rio-cloud/rio-uikit/components/listMenu/ListMenuGroup';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { MouseEventHandler, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { releaseNotesData } from '../../../utils/release-notes';

type ReleaseNotesProps = {
  isOpen: boolean;
  onClose: () => void;
};

const texts = {
  filterPlaceholder: 'common.releaseNotes.filterPlaceholder',
  notFoundMessage: 'common.releaseNotes.notFoundMessage',
  buttonLabel: 'common.releaseNotes.button.label',
  title: 'common.releaseNotes.title',
};

const classes = {
  active: 'active',
  listMenu: 'width-20pct',
  content: 'width-80pct padding-y-5',
  noteContainer: 'border border-color-gray-light rounded relative padding-x-25',
  header: 'margin-y-20',
  date: 'margin-y-20 text-size-16 text-color-black',
  img: 'width-100pct padding-10',
  footerButton: 'btn btn-primary btn-outline',
};

const sortedReleaseNotesData = releaseNotesData.sort((a, b) => {
  return parseFloat(b.version) - parseFloat(a.version);
});

const menuItems = (active: string, setActive: MouseEventHandler<HTMLSpanElement>): ListMenuItem[] => [
  {
    group: 'Versions',
    navItems: sortedReleaseNotesData.map((release, index) => ({
      key: release.version,
      item: (
        <span className={active === index.toString() ? classes.active : ''} data-key={index} onClick={setActive}>
          {release.version}
        </span>
      ),
    })),
  },
];

const ReleaseNotes = ({ isOpen, onClose }: ReleaseNotesProps) => {
  const intl = useIntl();

  const [activeItem, setActiveItem] = useState('0');
  const [activeReleaseNote, setActiveReleaseNote] = useState(sortedReleaseNotesData[0]);

  const onItemClick = (event: MouseEvent) => {
    const target = event.target;

    if (target instanceof HTMLElement) {
      const key = target.getAttribute('data-key') ?? '';
      setActiveItem(key);

      if (key !== null) {
        const selectedRelease = sortedReleaseNotesData[parseInt(key, 10)];
        setActiveReleaseNote(selectedRelease);
      }
    }
  };

  const footer = (
    <div>
      <button type="button" className={classes.footerButton} onClick={onClose}>
        <span className="text-capitalize">{intl.formatMessage({ id: texts.buttonLabel })}</span>
      </button>
    </div>
  );

  const body = (
    <div className="display-flex gap-10 width-100pct">
      <ListMenu
        className={classes.listMenu}
        menuItems={menuItems(activeItem, onItemClick)}
        enableFilter
        focusFilter
        filterPlaceholder={texts.filterPlaceholder}
        notFoundMessage={texts.notFoundMessage}
      />
      <div className={classes.content}>
        <div className={classes.noteContainer}>
          <div className="display-flex justify-content-between align-items-center">
            <h3 className={classes.header}>Version {activeReleaseNote.version}</h3>
            <span className={classes.date}>{activeReleaseNote.date}</span>
          </div>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{activeReleaseNote.notes}</ReactMarkdown>
          {activeReleaseNote.image && (
            <img src={activeReleaseNote.image} alt={`Release ${activeReleaseNote.version}`} className={classes.img} />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <Dialog
      show={isOpen}
      title={intl.formatMessage({ id: texts.title })}
      footer={footer}
      body={body}
      showCloseButton
      bsSize="full"
      useOverflow
      onClose={onClose}
    />
  );
};

export default ReleaseNotes;
