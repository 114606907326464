import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { config } from './config';
import { main } from './configuration';
import { reduxStore } from './store/store';
import { handleLoginRedirect } from './configuration/login/redirect';
import { ErrorBoundary } from './components/atom/ErrorBoundary/ErrorBoundary';
import App from './layout/App';
import AppDialog from './components/template/AppDialog/AppDialog';
import { IntlProvider } from 'react-intl';
import { useAppSelector } from './configuration/setup/hooks';
import { getDisplayMessages, getLocale } from './configuration/lang/langSlice';
import { DEFAULT_LOCALE } from './configuration/lang/lang';

const Root = () => {
  const userLocale = useAppSelector(getLocale);
  const displayMessages = useAppSelector(getDisplayMessages);

  return (
    <IntlProvider
      defaultLocale={DEFAULT_LOCALE}
      key={userLocale}
      locale={userLocale ?? 'de'}
      messages={displayMessages}
    >
      <AppDialog />
      <App />
    </IntlProvider>
  );
};

const renderApplication = () => {
  createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
      <ErrorBoundary>
        <BrowserRouter>
          <Provider store={reduxStore}>
            <Root />
          </Provider>
        </BrowserRouter>
      </ErrorBoundary>
    </StrictMode>,
  );
};

const isDev = import.meta.env.DEV;
const isProd = import.meta.env.PROD;
const isProdPreview = import.meta.env.VITE_PRODUCTION_PREVIEW;
const nonMSWTestingURL = import.meta.env.VITE_NON_MSW_API_BASE_URL_FOR_TESTING;

if ((isDev && config.enableMockServer) || isProdPreview) {
  import('../mocks/browser')
    .then(({ startWorker }) => startWorker(nonMSWTestingURL))
    .then(() => main(renderApplication));
}

if (window.location.href.startsWith(config.login.redirectUri as string)) {
  handleLoginRedirect();
} else if (isProd && !isProdPreview) {
  main(renderApplication);
}
