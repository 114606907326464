import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../../utils/prepareHeaders';

export interface User {
  id: { name: string; value: string };
  name: {
    title: string;
    first: string;
    last: string;
  };
  email: string;
  picture: {
    large: string;
    medium: string;
    thumbnail: string;
  };
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://randomuser.me/api/', prepareHeaders }),
  endpoints: (builder) => ({
    fetchUsers: builder.query<User[], string | undefined>({
      query: () => ({
        url: '?results=10&inc=id,name,email,picture',
        responseHandler: async (response: Response) => {
          const data = await response.json();
          return data.results;
        },
      }),
      keepUnusedDataFor: 10,
    }),
  }),
});

export const { useFetchUsersQuery } = userApi;
