import FeedbackRating from '@rio-cloud/rio-uikit/FeedbackRating';
import { useIntl } from 'react-intl';

type StarRatingProps = {
  onChange: (value: number | undefined) => void;
};

const StarRating = ({ onChange }: StarRatingProps) => {
  const intl = useIntl();
  const rating = (
    <FeedbackRating
      count={5}
      leftLabel={intl.formatMessage({ id: 'feedbackPage.starRating.veryPoor.label' })}
      rightLabel={intl.formatMessage({ id: 'feedbackPage.starRating.excellent.label' })}
      circleButtons
      onRatingChanged={onChange}
      className="margin-bottom-0"
    />
  );

  return <div className="padding-top-5">{rating}</div>;
};

export default StarRating;
