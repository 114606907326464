import { useIntl } from 'react-intl';

const getDatesToTranslations = () => {
  const intl = useIntl();

  const now = new Date();
  const lastMonth = now.getMonth() === 0 ? 11 : now.getMonth() - 1;
  const year = now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();
  const lastMonthDate = new Date(year, lastMonth);
  const currentMonth = now.getMonth() + 1;
  const currentYear = now.getFullYear();
  const currentMonthDate = new Date(now.getFullYear(), now.getMonth());
  const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

  const currentQuarter = Math.ceil(currentMonth / 3);
  let lastQuarter = currentQuarter - 1;
  let yearOfLastQuarter = currentYear;

  if (lastQuarter === 0) {
    lastQuarter = 4;
    yearOfLastQuarter--;
  }

  const quarterStartMonth = (lastQuarter - 1) * 3 + 1;
  const quarterEndMonth = quarterStartMonth + 2;

  const startDate = new Date(year, lastMonth, 1);
  const endDate = new Date(year, lastMonth + 1, 0);
  const threeMonthsAgoMonth = now.getMonth() - 3 < 0 ? 12 + (now.getMonth() - 3) : now.getMonth() - 3;
  const threeMonthsAgoYear = now.getMonth() - 3 < 0 ? now.getFullYear() - 1 : now.getFullYear();
  const threeMonthsAgoDate = new Date(threeMonthsAgoYear, threeMonthsAgoMonth);
  const sixMonthsAgoMonth = now.getMonth() - 6 < 0 ? 12 + (now.getMonth() - 6) : now.getMonth() - 6;
  const sixMonthsAgoYear = now.getMonth() - 6 < 0 ? now.getFullYear() - 1 : now.getFullYear();
  const sixMonthsAgoDate = new Date(sixMonthsAgoYear, sixMonthsAgoMonth);
  const startQuarterDate = new Date(yearOfLastQuarter, quarterStartMonth - 1, 1);
  const endQuarterDate = new Date(yearOfLastQuarter, quarterEndMonth, 0);
  const twoWeeksAgoDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14);
  const oneWeekAgoDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);

  const dates = {
    currentMonth: intl.formatDate(currentMonthDate, { year: 'numeric', month: 'long' }),
    threeMonthsAgo: intl.formatDate(threeMonthsAgoDate, { year: 'numeric', month: 'long', day: 'numeric' }),
    threeMonthsAgoMonth: intl.formatDate(threeMonthsAgoDate, { year: 'numeric', month: 'long' }),
    sixMonthsAgo: intl.formatDate(sixMonthsAgoDate, { year: 'numeric', month: 'long', day: 'numeric' }),
    lastMonth: intl.formatDate(lastMonthDate, { year: 'numeric', month: 'long' }),
    startLastMonth: intl.formatDate(startDate, { year: 'numeric', month: 'long', day: 'numeric' }),
    endLastMonth: intl.formatDate(endDate, { year: 'numeric', month: 'long', day: 'numeric' }),
    startQuarter: intl.formatDate(startQuarterDate, { year: 'numeric', month: 'long', day: 'numeric' }),
    endQuarter: intl.formatDate(endQuarterDate, { year: 'numeric', month: 'long', day: 'numeric' }),
    startQuarterMonth: intl.formatDate(startQuarterDate, { year: 'numeric', month: 'long' }),
    endQuarterMonth: intl.formatDate(endQuarterDate, { year: 'numeric', month: 'long' }),
    twoWeeksAgo: intl.formatDate(twoWeeksAgoDate, { year: 'numeric', month: 'long', day: 'numeric' }),
    oneWeekAgo: intl.formatDate(oneWeekAgoDate, { year: 'numeric', month: 'long', day: 'numeric' }),
    yesterday: intl.formatDate(yesterday, { year: 'numeric', month: 'long', day: 'numeric' }),
  };

  return dates;
};
export default getDatesToTranslations;
