import { ChatActions } from '../../molecule/ChatActions/ChatActions';
import { CommentDialog } from '../CommentDialog/CommentDialog';
import { useEffect, useState } from 'react';
import { useSubmitFeedbackMutation } from '../../../services/feedbackApi/feedback';
import styled from 'styled-components';
import Popover from '@rio-cloud/rio-uikit/components/popover/Popover';
import Notification from '@rio-cloud/rio-uikit/components/notification/Notification';
import OverlayTrigger from '@rio-cloud/rio-uikit/components/overlay/OverlayTrigger';
import { useDispatch, useSelector } from 'react-redux';
import { addNewFeedback, Feedback, updateFeedback } from '../../../store/feedback/feedbackSlice';
import useClipboard from '@rio-cloud/rio-uikit/hooks/useClipboard';
import { selectLastUserMessageBefore, selectMessageById } from '../../../store/chat/chat.slice';
import { FormattedMessage } from 'react-intl';
import { Service } from '../../../store/service/serviceSlice';
import { Steps } from '../../../store/onboarding/onboardingSlice';
import { getUserId } from '../../../utils/getUserId';
import { getUserProfile } from '../../../configuration/login/loginSlice';
import { ChatFeedbackOptions } from '../../template/ChatFeedbackOptions/ChatFeedbackOptions';

const feedbackIcons = ['helpful', 'notHelpful', 'comment'] as const;

export type FeedbackIcons = (typeof feedbackIcons)[number];

type IconProps = {
  iconName: FeedbackIcons;
  iconClass: string;
  tooltip: string;
  additionalClass?: string;
  dataTestId?: string;
};

const icons: IconProps[] = [
  {
    iconName: 'helpful',
    iconClass: 'rioglyph-thumbs-up',
    tooltip: 'chatPage.messageActionBar.tooltip.helpful.label',
    additionalClass: 'position-relative bottom-3',
    dataTestId: 'icon-helpful',
  },
  {
    iconName: 'notHelpful',
    iconClass: 'rioglyph-thumbs-down',
    tooltip: 'chatPage.messageActionBar.tooltip.notHelpful.label',
    additionalClass: 'position-relative',
    dataTestId: 'icon-not-helpful',
  },
  {
    iconName: 'comment',
    iconClass: 'rioglyph-send',
    tooltip: 'chatPage.messageActionBar.tooltip.comment.label',
    additionalClass: 'text-size-20 padding-x-3',
    dataTestId: 'icon-comment',
  },
] as const;

type MessageActionBarProps = {
  messageId: number;
  handleSend: (content: string, service: Service, isOnboarding: boolean, nextStepIndex?: Steps) => void;
};

export const MessageActionBar = ({ messageId, handleSend }: MessageActionBarProps) => {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [activeIcon, setActiveIcon] = useState<Omit<FeedbackIcons, 'comment'> | null>(null);
  const [currentFeedback, setCurrentFeedback] = useState<Feedback | undefined>(undefined);
  const [submitFeedback, { isLoading }] = useSubmitFeedbackMutation();
  const answer = useSelector(selectMessageById(messageId));
  const question = useSelector(selectLastUserMessageBefore(messageId));
  const clipboard = useClipboard();
  const user = useSelector(getUserProfile);
  const userId = getUserId(user?.sub ?? '');

  const handleClose = () => {
    setShowDialog(false);
    document.querySelector('.modal')?.classList.toggle('show');
    setCurrentFeedback(undefined);
  };

  const handleSendFeedback = async (iconName: FeedbackIcons) => {
    const feedback: Omit<Feedback, 'id'> = {
      lastQuestionId: question ? question.id : null,
      lastAnswerId: answer.id,
      detail: '',
      rate: iconName === 'helpful' ? 5 : 1,
      userId,
    };

    const result = dispatch(addNewFeedback(feedback));
    const newFeedback = result.payload as Feedback;

    if (iconName !== 'comment') {
      setActiveIcon(iconName);
    }

    setCurrentFeedback(newFeedback);

    if (feedback.rate === 1) {
      setShowPopover(false);
      setShowDialog(true);
    } else if (feedback.rate === 5) {
      sendFeedback(newFeedback);
    }
  };
  const sendFeedback = async (feedbackWithId: Feedback) => {
    try {
      if (!feedbackWithId) {
        return;
      }
      const result = await submitFeedback(feedbackWithId).unwrap();
      if (result) {
        Notification.success(<FormattedMessage id="common.feedback.success.notification" />);
      }
    } catch (error) {
      Notification.error(<FormattedMessage id="common.feedback.error.notification" />);
    }
  };

  const handleUpdateDetails = async (details: string) => {
    setShowPopover(false);
    setShowDialog(false);
    if (currentFeedback) {
      const updatedFeedback = {
        ...currentFeedback,
        detail: details,
        userId,
      };
      dispatch(updateFeedback(updatedFeedback));
      await sendFeedback(updatedFeedback);
    }
  };

  const handleCopy = () => {
    clipboard.copy(answer.content);
    Notification.success(<FormattedMessage id="chatPage.messageActionBar.tooltip.copy.notification" />);
  };

  useEffect(() => {
    if (showDialog) {
      setShowPopover(false);
    }
  }, [showDialog]);

  return (
    <ActionButtonWrapper>
      <OverlayTrigger
        show={showPopover}
        onToggle={setShowPopover}
        trigger="click"
        key="bottom-end"
        placement="bottom-end"
        overlay={
          <CustomPopover id="popover-positioned-bottom-end">
            <div className="btn-toolbar display-flex justify-content-center align-items-center gap-3">
              {icons.map((icon) => (
                <ChatFeedbackOptions
                  key={`icon-${icon.iconName}`}
                  iconName={icon.iconName}
                  iconClass={icon.iconClass}
                  tooltip={icon.tooltip}
                  additionalClass={icon.additionalClass}
                  clickAction={() => handleSendFeedback(icon.iconName)}
                  isActive={activeIcon === icon.iconName}
                  data-testid={icon.dataTestId}
                />
              ))}
              {<Divider className="margin-x-5" />}
              <ChatActions
                handleCopy={handleCopy}
                handleSend={handleSend}
                message={answer}
                question={question?.content ?? ''}
              />
            </div>
          </CustomPopover>
        }
      >
        <span className="rioglyph rioglyph-option-vertical text-color-primary text-size-16" />
      </OverlayTrigger>
      <CommentDialog
        showDialog={showDialog}
        handleClose={handleClose}
        question={question ? question.content : null}
        answer={answer.content}
        updateDetails={handleUpdateDetails}
        isLoading={isLoading}
      />
    </ActionButtonWrapper>
  );
};

const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  cursor: pointer;
  height: 10px;
  font-size: 2.5rem;
  color: var(--gray-darkest);
  & > .rioglyph {
    position: relative;
    top: 2px;
    left: 14px;
  }
`;

const CustomPopover = styled(Popover)`
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
  border: none;
  & .arrow {
    display: none;
  }

  & .popover-content {
    padding: 0 1.2rem !important;
  }
`;

const Divider = styled.div`
  width: 1px;
  background-color: var(--gray-light);
  height: 20px;
`;
