import { FormattedMessage, useIntl } from 'react-intl';
import PageNavigation from '../PageNavigation/PageNavigation';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../store/about/aboutSlice';

const texts = {
  intro: 'getStartedPage.howAssistantWorksPrompt.introduction',
  steps: [
    {
      title: 'getStartedPage.howAssistantWorksPrompt.step_one.title',
      description: 'getStartedPage.howAssistantWorksPrompt.step_one.description',
    },
    {
      title: 'getStartedPage.howAssistantWorksPrompt.step_two.title',
      description: 'getStartedPage.howAssistantWorksPrompt.step_two.description',
    },
    {
      title: 'getStartedPage.howAssistantWorksPrompt.step_three.title',
      description: 'getStartedPage.howAssistantWorksPrompt.step_three.description',
    },
    {
      title: 'getStartedPage.howAssistantWorksPrompt.step_four.title',
      description: 'getStartedPage.howAssistantWorksPrompt.step_four.description',
    },
    {
      title: 'getStartedPage.howAssistantWorksPrompt.step_five.title',
      description: 'getStartedPage.howAssistantWorksPrompt.step_five.description',
    },
  ],
  visualizationSection: {
    title: 'getStartedPage.howAssistantWorksPrompt.diagram.title',
    description: 'getStartedPage.howAssistantWorksPrompt.diagram.description',
    imgAlt: 'getStartedPage.howAssistantWorksPrompt.diagram.alt',
  },
  pageNavigation: {
    previousLabel: 'getStartedPage.whatIsFleetAssistant.label',
    nextLabel: 'getStartedPage.howToWritePrompts.label',
  },
};

const classes = {
  container: 'panel panel-default panel-body panel-blank padding-x-25 margin-top-25',
  introText: 'line-height-150rel',
  timeline: 'timeline margin-top-50',
  timelineElement: 'timeline-element margin-bottom-0',
  timelineDate: 'timeline-date timeline-date-small timeline-date-thin margin-right-10 margin-top-5',
  timelineDot: 'width-10 aspect-ratio-1 rounded-circle border border-color-info border-width-2 bg-white margin-left-1',
  timelineContent: 'timeline-content margin-bottom-20 text-color-darker',
  section: 'margin-top-40 margin-bottom-20',
  sectionTitle: 'text-size-h3 text-bold margin-bottom-10',
  sectionDescription: 'line-height-150rel',
  imageContainer: 'text-center margin-bottom-20',
  image: 'max-width-90pct bg-darkest padding-y-25 padding-x-50',
};

const HowAssistantWorks = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const pageTopRef = useRef<HTMLDivElement>(null);

  const handlePreviousSection = () => dispatch(setCurrentPage('page2'));
  const handleNextSection = () => dispatch(setCurrentPage('page4'));

  useEffect(() => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className={classes.container}>
      <div ref={pageTopRef} style={{ position: 'absolute', top: '0' }} />
      <p className={classes.introText}>
        <FormattedMessage id={texts.intro} />
      </p>

      <div className={classes.timeline}>
        {texts.steps.map((step, index) => (
          <div key={index} className={classes.timelineElement}>
            <div className={classes.timelineDate}>
              <div className={classes.timelineDot} />
            </div>
            <div className={classes.timelineContent}>
              <strong>
                <FormattedMessage id={step.title} />
              </strong>
              <p>
                <FormattedMessage id={step.description} />
              </p>
            </div>
          </div>
        ))}
      </div>

      <section className={classes.section}>
        <h3 className={classes.sectionTitle}>
          <FormattedMessage id={texts.visualizationSection.title} />
        </h3>
        <p className={classes.sectionDescription}>
          <FormattedMessage id={texts.visualizationSection.description} />
        </p>
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src="assets/images/diagram.svg"
            alt={intl.formatMessage({ id: texts.visualizationSection.imgAlt })}
          />
        </div>
      </section>
      <PageNavigation
        onNext={handleNextSection}
        onPrevious={handlePreviousSection}
        previousLabel={texts.pageNavigation.previousLabel}
        nextLabel={texts.pageNavigation.nextLabel}
      />
    </div>
  );
};

export default HowAssistantWorks;
