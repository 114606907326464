import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import { useIntl } from 'react-intl';

type ErrorStateMessageProps = {
  message: string;
};

const ErrorStateMessage = ({ message }: ErrorStateMessageProps) => {
  const intl = useIntl();
  return (
    <div className="display-flex justify-content-center align-items-center">
      <ErrorState
        headline={intl.formatMessage({ id: 'common.error.label' })}
        message={message ? message : intl.formatMessage({ id: 'common.error' })}
      />
    </div>
  );
};

export default ErrorStateMessage;
