import { useState } from 'react';
import Button from '@rio-cloud/rio-uikit/Button';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Spinner from '@rio-cloud/rio-uikit/components/spinner/Spinner';
import SmoothScrollbars from '@rio-cloud/rio-uikit/components/smoothScrollbars/SmoothScrollbars';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { markdownRenderers } from '../../../utils/markdownRenderers/markdownRenderers';
import remarkGfm from 'remark-gfm';

type CommentDialogProps = {
  showDialog: boolean;
  handleClose: () => void;
  question: string | null;
  answer: string;
  isLoading: boolean;
  updateDetails: (details: string) => void;
};

const texts = {
  title: 'common.feedback.feedbackRequest.title',
  subtitle: 'common.feedback.feedbackRequest.appeal',
  howToImproveInquiry: 'chatPage.commentDialog.feedback.howImproveAnswer.inquiry',
  howToImproveAppeal: 'chatPage.commentDialog.feedback.howImproveAnswer.appeal',
  commentPlaceholder: 'common.feedback.comment.placeholder',
  questionLabel: 'chatPage.commentDialog.feedback.question.label',
  answerLabel: 'chatPage.commentDialog.feedback.answer.label',
  showMore: 'common.showMore.toggle',
  showLess: 'common.showLess.toggle',
  submit: 'common.feedback.submit.label',
  validationInvalidCharactersError: 'common.feedback.validation.invalidCharacters.error',
  validationEmptyError: 'common.feedback.validation.empty.error',
  validationTooLongError: 'common.feedback.validation.tooLong.error',
};

const classes = {
  toolbar: 'btn-toolbar margin-bottom-20',
  spinnerWrapper: 'display-flex justify-content-center align-items-center',
  textMedium: 'text-medium text-size-18 margin-bottom-0',
  formGroup: 'form-group',
  questionLabel: 'text-right line-height-20 padding-right-5 text-medium text-size-16',
  answerLabel: 'text-right line-height-20 padding-right-5 text-medium text-size-16',
  flexColumn: 'display-flex flex-column justify-content-start align-items-start',
};

export const CommentDialog = ({
  showDialog,
  isLoading,
  handleClose,
  question,
  answer,
  updateDetails,
}: CommentDialogProps) => {
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [comment, setComment] = useState<string>('');
  const [answerVisibility, setAnswerVisibility] = useState(false);
  const intl = useIntl();

  const validateInput = (value: string): string | boolean => {
    const specialCharacterRegex = /^[^{}<>]*$/;

    if (!specialCharacterRegex.test(value)) return intl.formatMessage({ id: texts.validationInvalidCharactersError });

    if (value.trim().length === 0) return intl.formatMessage({ id: texts.validationEmptyError });

    if (value.length > 300) return intl.formatMessage({ id: texts.validationTooLongError });

    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationResult = validateInput(comment);

    if (validationResult === true) {
      updateDetails(comment);
    } else {
      console.log(validationResult);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    setComment(value);
    const isValid = validateInput(value) === true;
    setEnableSubmit(isValid && value.trim().length > 0);
  };

  return (
    <div className={classes.toolbar}>
      <Dialog show={showDialog} onClose={handleClose} showCloseButton bsSize="md">
        {isLoading ? (
          <Dialog.Body>
            <div className={classes.spinnerWrapper} style={{ minHeight: '300px' }}>
              <Spinner data-testid="spinner" />
            </div>
          </Dialog.Body>
        ) : (
          <form onSubmit={handleSubmit}>
            <Dialog.Title
              title={<FormattedMessage id={texts.title} />}
              subtitle={<FormattedMessage id={texts.subtitle} />}
            />
            <SmoothScrollbars className="height-50vh">
              <Dialog.Body>
                <div>
                  <div className={classes.formGroup}>
                    <p className={classes.textMedium}>
                      <FormattedMessage id={texts.howToImproveInquiry} />
                    </p>
                    <label htmlFor="commentInput">
                      <FormattedMessage id={texts.howToImproveAppeal} />
                    </label>
                    <textarea
                      className="form-control"
                      name="commentInput"
                      placeholder={intl.formatMessage({ id: texts.commentPlaceholder })}
                      onChange={handleInputChange}
                      value={comment}
                      rows={4}
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <div className={classes.flexColumn}>
                      <label className={classes.questionLabel}>
                        <FormattedMessage id={texts.questionLabel} />:
                      </label>
                      <div>{question}</div>
                    </div>
                  </div>
                  <div className={classes.formGroup}>
                    <div className={classes.flexColumn}>
                      <label className={classes.answerLabel}>
                        <FormattedMessage id={texts.answerLabel} />:
                      </label>
                      <AnswerWrapper expanded={answerVisibility}>
                        <ReactMarkdown components={markdownRenderers(answer)} remarkPlugins={[remarkGfm]}>
                          {answer}
                        </ReactMarkdown>
                      </AnswerWrapper>
                      {answer.length > 400 && (
                        <>
                          {!answerVisibility && (
                            <ShowAnswer onClick={() => setAnswerVisibility(true)}>
                              <FormattedMessage id={texts.showMore} />
                            </ShowAnswer>
                          )}
                          {answerVisibility && (
                            <ShowAnswer onClick={() => setAnswerVisibility(false)}>
                              <FormattedMessage id={texts.showLess} />
                            </ShowAnswer>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Body>
            </SmoothScrollbars>
            <Dialog.Footer>
              <div>
                <Button bsStyle="primary" type="submit" disabled={!enableSubmit}>
                  <FormattedMessage id={texts.submit} />
                </Button>
              </div>
            </Dialog.Footer>
          </form>
        )}
      </Dialog>
    </div>
  );
};

const ShowAnswer = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: var(--brand-primary);

  &:hover {
    color: var(--color-highlight-dark);
  }
`;

const AnswerWrapper = styled.div<{ expanded: boolean }>`
  max-height: ${(props) => (props.expanded ? 'none' : '10rem')};
  overflow: hidden;
  line-height: 1.5rem;
  position: relative;
  transition: max-height 0.3s ease;
  margin-bottom: 2rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${(props) => (props.expanded ? '0' : '1.5rem')};
  }
`;
