import Button from '@rio-cloud/rio-uikit/components/button/Button';
import InfoDialog from '@rio-cloud/rio-uikit/components/dialog/InfoDialog';
import { FormattedMessage } from 'react-intl';

export const SuccessMessage = ({
  show,
  handleClose,
  message,
}: {
  show: boolean;
  handleClose: () => void;
  message: string;
}) => (
  <InfoDialog
    show={show}
    onClose={handleClose}
    showCloseButton
    content={
      <div className="text-center">
        <span className="rioglyph rioglyph-ok-sign text-color-success text-size-h1" />
        <div className="margin-bottom-25">
          <h2>
            <FormattedMessage id="common.successMessage.thanks.label" />
          </h2>
          <p>
            <FormattedMessage id={message} />
          </p>
        </div>
        <Button className="margin-bottom-20 text-end" onClick={handleClose}>
          Ok
        </Button>
      </div>
    }
  />
);
