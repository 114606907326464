import { Middleware } from '@reduxjs/toolkit';
import { getLocale } from './langSlice';
import { extractLanguage } from './lang';

const languageMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action);

  const lang = extractLanguage(getLocale(store.getState()));
  const html = document.querySelector('html');

  if (html && lang && html.getAttribute('lang') !== lang) {
    html.setAttribute('lang', lang);
  }

  return result;
};

export default languageMiddleware;
