import Button from '@rio-cloud/rio-uikit/components/button/Button';
import { closeDialog, DialogVariant, selectDialogDetails, selectIsDialogOpen } from '../../../store/dialog/dialogSlice';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { FormattedMessage } from 'react-intl';

type IconDetail = {
  iconName: string;
  iconColor: string;
};

type VariantToIcon = {
  [key in DialogVariant]: IconDetail;
};

const variantToIcon: VariantToIcon = {
  success: {
    iconName: 'rioglyph-ok-sign',
    iconColor: 'text-color-success',
  },
  error: {
    iconName: 'rioglyph-error-sign',
    iconColor: 'text-color-danger',
  },
};

const AppDialog = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsDialogOpen);
  const dialogDetail = useSelector(selectDialogDetails);

  const handleClose = () => dispatch(closeDialog());
  return (
    <Dialog
      show={isOpen}
      onClose={handleClose}
      showCloseButton
      useOverflow
      body={
        <div className="text-center">
          <span
            className={`rioglyph 
              ${dialogDetail && variantToIcon[dialogDetail.variant as DialogVariant] && variantToIcon[dialogDetail.variant as DialogVariant].iconName} 
               ${dialogDetail && variantToIcon[dialogDetail.variant as DialogVariant] && variantToIcon[dialogDetail.variant as DialogVariant].iconColor}
              text-size-h1`}
          />
          <div className="margin-bottom-25">
            <h2
              className={`h3 ${dialogDetail && variantToIcon[dialogDetail.variant as DialogVariant] && variantToIcon[dialogDetail.variant as DialogVariant].iconColor}`}
            >
              {dialogDetail.variant === 'error' ? 'Error' : 'Success'}
            </h2>
            <p>{dialogDetail.message}</p>
            {dialogDetail.variant === 'error' ? (
              <p>
                <FormattedMessage id="common.contactAdmin.info" />
              </p>
            ) : null}
          </div>
          <Button className="margin-bottom-20" onClick={handleClose}>
            Ok
          </Button>
        </div>
      }
    />
  );
};

export default AppDialog;
