import { createSlice } from '@reduxjs/toolkit';

export const steps = ['step-0', 'step-1', 'step-2', 'step-3', 'step-4'] as const;
export type Steps = (typeof steps)[number];

export type MockMessages = {
  [key in Steps]: {
    question: string;
    answer: string;
  };
};

const getInitialOnboardingState = (): boolean => {
  const savedState = localStorage.getItem('isOnboardingMode');
  return savedState ? JSON.parse(savedState) : true;
};

export type OnboardingState = {
  isOnboardingMode: boolean;
  onboardingStep: Steps | undefined;
  showOnboardingTip: boolean;
  messageId: number | undefined;
  isDemoFlowEnabled: boolean;
  isDemoFlowFinish: boolean;
};

const initialState: OnboardingState = {
  isOnboardingMode: getInitialOnboardingState(),
  onboardingStep: undefined,
  showOnboardingTip: false,
  messageId: undefined,
  isDemoFlowEnabled: false,
  isDemoFlowFinish: false,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    toggleOnboardingMode: (state) => {
      state.isOnboardingMode = !state.isOnboardingMode;
      localStorage.setItem('isOnboardingMode', JSON.stringify(state.isOnboardingMode));
    },
    handleNextOnboardingStep: (state) => {
      if (state.onboardingStep !== undefined) {
        const currentIndex = steps.indexOf(state.onboardingStep);
        if (currentIndex < steps.length - 1) {
          state.onboardingStep = steps[currentIndex + 1];
        }
      } else {
        state.onboardingStep = 'step-1';
      }
    },
    resetStep: (state) => {
      state.onboardingStep = 'step-0';
    },
    disableOnboardingMode: (state) => {
      state.isOnboardingMode = false;
      localStorage.setItem('isOnboardingMode', JSON.stringify(false));
    },
    activateOnboardingMode: (state) => {
      state.isOnboardingMode = true;
      localStorage.setItem('isOnboardingMode', JSON.stringify(true));
    },
    showOnboardingTip: (state, action: { payload: { messageId: number } }) => {
      const { messageId } = action.payload;
      state.showOnboardingTip = true;
      state.messageId = state.showOnboardingTip ? messageId : undefined;
    },
    enableDemoFlow: (state) => {
      state.isDemoFlowEnabled = true;
      localStorage.setItem('isDemoFlowEnabled', JSON.stringify(true));
    },
    disableDemoFlow: (state) => {
      state.isDemoFlowEnabled = false;
      localStorage.setItem('isDemoFlowEnabled', JSON.stringify(false));
    },
    resetDemoFlow: (state) => {
      state.isDemoFlowEnabled = false;
      state.onboardingStep = 'step-0';
    },
    finishDemoFlow: (state) => {
      state.isDemoFlowFinish = true;
    },
  },
});

export const {
  toggleOnboardingMode,
  activateOnboardingMode,
  resetStep,
  handleNextOnboardingStep,
  disableOnboardingMode,
  showOnboardingTip,
  enableDemoFlow,
  disableDemoFlow,
  resetDemoFlow,
  finishDemoFlow,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;

export const selectIsOnboardingMode = (state: { onboarding: OnboardingState }) => state.onboarding.isOnboardingMode;

export const selectOnboardingStep = (state: { onboarding: OnboardingState }) => state.onboarding.onboardingStep;

export const selectShowOnboardingTip = (state: { onboarding: OnboardingState }) => state.onboarding.showOnboardingTip;

export const selectOnboardingMessageId = (state: { onboarding: OnboardingState }) => state.onboarding.messageId;

export const selectIsDemoFlowEnabled = (state: { onboarding: OnboardingState }) => state.onboarding.isDemoFlowEnabled;

export const selectIsDemoFlowFinish = (state: { onboarding: OnboardingState }) => state.onboarding.isDemoFlowFinish;
