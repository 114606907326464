import styled, { keyframes } from 'styled-components';

type OnboardingButton = {
  text: string;
  iconName?: string;
  onClick: () => void;
};

type OnboardingTipProps = {
  show: boolean;
  title: string;
  content: string;
  previousButton?: OnboardingButton;
  nextButton?: OnboardingButton;
  stepIndex: number;
};

const OnboardingTip = ({ show, title, content, previousButton, nextButton, stepIndex }: OnboardingTipProps) => {
  if (!show) return null;

  const buttonCount = (previousButton ? 1 : 0) + (nextButton ? 1 : 0);

  return (
    <Card stepIndex={stepIndex}>
      <Arrow />
      <Title>{title}</Title>
      <Content>{content}</Content>
      <ButtonContainer buttonCount={buttonCount}>
        {previousButton && (
          <StyledButton type="button" className="btn btn-secondary hover border-none" onClick={previousButton.onClick}>
            <span className={`rioglyph ${previousButton.iconName}`} aria-hidden="true" />
            {previousButton.text}
          </StyledButton>
        )}
        {nextButton && (
          <StyledButton type="button" className="btn btn-secondary border-color-white" onClick={nextButton.onClick}>
            {nextButton.text}
            {nextButton.iconName ? <span className={`rioglyph ${nextButton.iconName}`} aria-hidden="true" /> : null}
          </StyledButton>
        )}
      </ButtonContainer>
    </Card>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Card = styled.div<{ stepIndex: number }>`
  background-color: var(--brand-secondary);
  color: white;
  padding: 1.2rem;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 260px;
  position: fixed;
  top: ${({ stepIndex }) => `${50 + stepIndex * 50}px`};
  left: 60px;
  z-index: 1010;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

const Arrow = styled.div`
  position: absolute;
  top: 20px;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--brand-secondary);
  z-index: 1001;
`;

const Title = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
`;

const Content = styled.div`
  font-size: 1rem;
  margin-top: 0.5rem;
`;

const ButtonContainer = styled.div<{ buttonCount: number }>`
  display: flex;
  justify-content: ${({ buttonCount }) => (buttonCount === 1 ? 'flex-end' : 'space-between')};
  gap: 8px;
  margin-top: 1rem;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  background: var(--brand-primary);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: var(--brand-primary-dark);
  }
`;

export default OnboardingTip;
