import * as xlsx from 'node-xlsx';
import * as FileSaver from 'file-saver';

const extractTableDataFromMarkdown = (markdownContent: string) => {
  const tableData: string[][] = [];

  const lines = markdownContent.split('\n');

  lines.forEach((line) => {
    if (line.includes('|')) {
      const row = line
        .split('|')
        .map((cell) => cell.trim().replace(/\*\*/g, ''))
        .filter(Boolean);

      if (row.every((cell) => !/^[-]+$/.test(cell))) {
        tableData.push(row);
      }
    }
  });

  return tableData;
};

export const exportMarkdownTableToXLSX = (markdownContent: string, fileName: string) => {
  const tableData = extractTableDataFromMarkdown(markdownContent);

  if (tableData.length > 0) {
    const data = [
      {
        name: 'Data',
        data: tableData,
      },
    ];

    const buffer = xlsx.build(data);

    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(blob, `${fileName}.xlsx`);
  }
};
