import { Fragment } from 'react/jsx-runtime';
import { useIntl } from 'react-intl';
import { exportMarkdownTableToPDF } from '../../../utils/export/exportMarkdownTableToPDF/exportMarkdownTableToPDF';
import { exportMarkdownTableToXLSX } from '../../../utils/export/exportMarkdownTableToExcel/exportMarkdownTableToExcel';

type TableActionBarProps = {
  message: string;
};

const TableActionBar = ({ message }: TableActionBarProps) => {
  const intl = useIntl();
  const items = [
    {
      value: (
        <Fragment>
          <span className="rioglyph rioglyph-csv margin-right-10" aria-hidden="true" />
          <span className="text-capitalize">Excel</span>
        </Fragment>
      ),
      onSelect: () => exportMarkdownTableToXLSX(message, 'data'),
    },
    {
      value: (
        <Fragment>
          <span className="rioglyph rioglyph-pdf-file margin-right-10" aria-hidden="true" />
          <span className="text-capitalize">PDF</span>
        </Fragment>
      ),
      onSelect: () => exportMarkdownTableToPDF(message, 'data'),
    },
  ];

  return (
    <div className="display-flex justify-content-end align-items-center gap-10 margin-y-10">
      <span className="text-medium">{intl.formatMessage({ id: 'chatPage.chatActions.table.export.dataTable' })}</span>
      <span className="display-flex justify-content-end align-items-center">
        <button
          type="button"
          className="btn btn-primary btn-link"
          onClick={() => exportMarkdownTableToXLSX(message, 'data')}
        >
          <span className="rioglyph rioglyph-csv" aria-hidden="true" />
          <span className="text-capitalize">Excel</span>
        </button>
        <button
          type="button"
          className="btn btn-primary btn-link"
          onClick={() => exportMarkdownTableToPDF(message, 'data')}
        >
          <span className="rioglyph rioglyph-pdf-file" aria-hidden="true" />
          <span className="text-capitalize">PDF</span>
        </button>
      </span>
    </div>
  );
};

export default TableActionBar;
