type FAQs = {
  id: string;
  translation: string;
  icon: string;
};

export const getMockFAQs = (): FAQs[] => [
  {
    id: '1-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_one',
    icon: 'stats',
  },
  {
    id: '2-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_two',
    icon: 'stats',
  },
  {
    id: '3-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_three',
    icon: 'stats',
  },
  {
    id: '4-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_four',
    icon: 'stats',
  },
  {
    id: '5-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_five',
    icon: 'stats',
  },
  {
    id: '6-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_six',
    icon: 'stats',
  },
  {
    id: '7-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_seven',
    icon: 'stats',
  },
  {
    id: '8-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_eight',
    icon: 'stats',
  },
  {
    id: '9-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_nine',
    icon: 'stats',
  },
  {
    id: '10-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_ten',
    icon: 'stats',
  },
  {
    id: '11-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_eleven',
    icon: 'stats',
  },
  {
    id: '12-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_twelve',
    icon: 'stats',
  },
  {
    id: '13-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_thirteen',
    icon: 'stats',
  },
  {
    id: '14-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_fourteen',
    icon: 'stats',
  },
  {
    id: '15-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_fifteen',
    icon: 'stats',
  },
  {
    id: '16-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_sixteen',
    icon: 'stats',
  },
  {
    id: '17-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_seventeen',
    icon: 'stats',
  },
  {
    id: '18-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_eighteen',
    icon: 'stats',
  },
  {
    id: '19-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_nineteen',
    icon: 'stats',
  },
  {
    id: '20-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_twenty',
    icon: 'stats',
  },
  {
    id: '21-question',
    translation: 'chatPage.servicesSection.exemplary_questions_performservice_twenty_one',
    icon: 'stats',
  },
  {
    id: '22-question',
    translation: 'chatPage.servicesSection.exemplary_questions_mantraining_one',
    icon: 'book',
  },
  {
    id: '23-question',
    translation: 'chatPage.servicesSection.exemplary_questions_mantraining_two',
    icon: 'book',
  },
  {
    id: '24-question',
    translation: 'chatPage.servicesSection.exemplary_questions_mantraining_three',
    icon: 'book',
  },
  {
    id: '25-question',
    translation: 'chatPage.servicesSection.exemplary_questions_mantraining_four',
    icon: 'book',
  },
  {
    id: '26-question',
    translation: 'chatPage.servicesSection.exemplary_questions_mantraining_five',
    icon: 'book',
  },
  {
    id: '27-question',
    translation: 'chatPage.servicesSection.exemplary_questions_mantraining_six',
    icon: 'book',
  },
];
