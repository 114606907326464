import { categorizeThreadsByDate } from '../../utils/categorizeThreadsByDate/categorizeThreadsByDate';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { threadApi } from '../../services/threadApi/threadApi';
import { Response } from '../../utils/types';
import { chatApi } from '../../services/chatApi/chatApi';

export type ChatThread = {
  id: string;
  title: string;
  timestamp: string;
  isActive: boolean;
  isFavorite: boolean;
};

type ThreadsState = {
  threads: ChatThread[];
  loadingThreadIds: string[];
  isFetchingHistory: boolean;
};

const createTodayThread = (): ChatThread => {
  const today = new Date().toISOString();
  return {
    id: '',
    title: '',
    timestamp: today,
    isActive: true,
    isFavorite: false,
  };
};

const initialState: ThreadsState = {
  threads: [createTodayThread()],
  loadingThreadIds: [],
  isFetchingHistory: false,
};

const threadSlice = createSlice({
  name: 'threads',
  initialState,
  reducers: {
    addNewThread: (state) => {
      state.threads.forEach((thread) => {
        thread.isActive = false;
      });
      const newThread = createTodayThread();
      state.threads.push(newThread);
      // sessionStorage.setItem('activeThread', newThread.id);
    },
    setActiveThread: (state, action: PayloadAction<string>) => {
      state.threads.forEach((thread) => {
        thread.isActive = thread.id === action.payload;
      });
      // sessionStorage.setItem('activeThread', action.payload);
      const activeThread = state.threads.find((thread) => thread.id === action.payload);
      if (!activeThread) {
        console.warn('No thread with the given ID found in state.');
      }
    },
    updateThreadTitle: (state, action: PayloadAction<{ title: string; threadId: string }>) => {
      const new_thread = state.threads.find((thread) => thread.id === action.payload.threadId);
      if (new_thread && !new_thread?.title) {
        new_thread.title = action.payload.title;
      }
    },
    addLoadingThreadId: (state, action: PayloadAction<string>) => {
      if (!state.loadingThreadIds.includes(action.payload)) {
        state.loadingThreadIds.push(action.payload);
      }
    },
    removeLoadingThreadId: (state, action: PayloadAction<string>) => {
      state.loadingThreadIds = state.loadingThreadIds.filter((id) => id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      threadApi.endpoints.fetchThreads.matchFulfilled,
      (state, action: PayloadAction<Response<ChatThread[]>>) => {
        // const activeThread = sessionStorage.getItem('activeThread');
        if (action.payload.data) {
          const updatedThreads: ChatThread[] = action.payload.data.map((thread) => ({
            ...thread,
            // isActive: activeThread === thread.id,
            isActive: false,
          }));

          const uniqueThreads = updatedThreads.filter(
            (newThread) => !state.threads.some((existingThread) => existingThread.id === newThread.id),
          );

          state.threads = [...state.threads, ...uniqueThreads];
        }
        state.isFetchingHistory = false;
      },
    );
    builder.addMatcher(chatApi.endpoints.sendMessage.matchFulfilled, (state, action) => {
      const responseThreadId = action.payload.threadId;
      if (responseThreadId) {
        const threadToUpdate = state.threads.find((thread) => thread.id.length === 0);
        if (threadToUpdate) {
          threadToUpdate.id = responseThreadId;
          threadToUpdate.isActive = true;
          // sessionStorage.setItem('activeThread', responseThreadId);
        }
      }
    });
    builder.addMatcher(threadApi.endpoints.fetchThreads.matchPending, (state) => {
      state.isFetchingHistory = true;
    });
    builder.addMatcher(threadApi.endpoints.fetchThreads.matchRejected, (state) => {
      state.isFetchingHistory = false;
    });
    builder.addMatcher(threadApi.endpoints.updateThread.matchFulfilled, (state, action) => {
      const { meta } = action;
      const threadId = meta?.arg?.originalArgs.threadId;
      const existingThreadIndex = state.threads.findIndex((thread) => thread.id === threadId);
      const { favorite, title } = meta?.arg?.originalArgs?.updateData;

      if (existingThreadIndex !== -1) {
        state.threads[existingThreadIndex] = {
          ...state.threads[existingThreadIndex],
          isFavorite: favorite !== undefined ? favorite : state.threads[existingThreadIndex].isFavorite,
          title: title !== undefined ? title : state.threads[existingThreadIndex].title,
        };
      }
    });
    builder.addMatcher(threadApi.endpoints.deleteThread.matchFulfilled, (state, action) => {
      const deletedThreadId = action.meta.arg.originalArgs.threadId;
      state.threads = state.threads.filter((thread) => thread.id !== deletedThreadId);
      // const activeThreadId = sessionStorage.getItem('activeThread');

      // if (activeThreadId === deletedThreadId) {
      //   sessionStorage.removeItem('activeThread');

      if (state.threads.length > 0) {
        const newActiveThread = state.threads[0];
        newActiveThread.isActive = true;
        // sessionStorage.setItem('activeThread', newActiveThread.id);
      }
      // }
    });
  },
});

export const { addNewThread, setActiveThread, updateThreadTitle, addLoadingThreadId, removeLoadingThreadId } =
  threadSlice.actions;
export default threadSlice.reducer;

export const selectCategorizedThreads = createSelector(
  (state: RootState) => state.thread.threads,
  (threads) => categorizeThreadsByDate(threads),
);

export const selectActiveThreadId = createSelector(
  (state: RootState) => state.thread.threads,
  (threads) => {
    const activeThread = threads.find((thread) => thread.isActive);
    if (!activeThread) {
      console.error('No active thread found.');
      // return sessionStorage.getItem('activeThread') || 'mockId';
      return 'mockId';
    }
    return activeThread.id;
  },
);

export const selectIsDemoThread = (threadId: string) => {
  return (state: RootState) => {
    const thread = state.thread.threads.find((th) => th.id === threadId);
    return thread ? thread.title.startsWith('DEMO') : false;
  };
};

export const selectIsThreadLoading = (threadId: string) => (state: RootState) => {
  return state.thread.loadingThreadIds.includes(threadId);
};

export const selectIsHistoryOfThreadsFetching = (state: RootState) => state.thread.isFetchingHistory;

export const selectFavoriteThreads = createSelector(
  (state: RootState) => state.thread.threads,
  (threads) => threads.filter((thread) => thread.isFavorite),
);

export const selectThreadTitleById = (threadId: string) => (state: RootState) => {
  const thread = state.thread.threads.find((th) => th.id === threadId);
  return thread ? thread.title : null;
};
