import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type DialogVariant = 'success' | 'error';

type DialogState = {
  isOpen: boolean;
  variant: DialogVariant | undefined;
  message: string | undefined;
};
const initialState: DialogState = {
  isOpen: false,
  variant: undefined,
  message: undefined,
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<Omit<DialogState, 'isOpen'>>) => {
      state.isOpen = true;
      state.variant = action.payload.variant;
      state.message = action.payload.message;
    },
    closeDialog: (state) => {
      state.isOpen = false;
      state.variant = undefined;
      state.message = undefined;
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export const selectIsDialogOpen = createSelector(
  (state: RootState) => state.dialog.isOpen,
  (isOpen) => isOpen,
);
export const selectDialogDetails = createSelector(
  (state: RootState) => ({
    variant: state.dialog.variant,
    message: state.dialog.message,
  }),
  (dialog) => dialog,
);

export default dialogSlice.reducer;
