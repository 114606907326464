import styled from 'styled-components';
import FeedbackForm from '../../organism/FeedbackForm/FeedbackForm';

const FeedbackPage = () => (
  <div className="container padding-y-25">
    <Container>
      <ContentWrapper>
        <FeedbackForm />
        <FeedbackImage src="assets/images/feedback.svg" alt="Feedback" />
      </ContentWrapper>
    </Container>
  </div>
);

export default FeedbackPage;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1680px;
  padding: 1rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  gap: 2rem;
`;

const FeedbackImage = styled.img`
  max-width: 300px;
  padding: 1rem;

  @media (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1465px) {
    max-width: 100%;
  }
`;
