import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../store/about/aboutSlice';
import PageNavigation from '../PageNavigation/PageNavigation';
const promptText = {
  tipsHeading: 'getStartedPage.howWritePromptsSection.tipsForGettingStarted.title',
  tips: [
    'getStartedPage.howWritePromptsSection.tipsForGettingStarted.tip_one',
    'getStartedPage.howWritePromptsSection.tipsForGettingStarted.tip_two',
    'getStartedPage.howWritePromptsSection.tipsForGettingStarted.tip_three',
  ],
  doHeading: 'getStartedPage.howWritePromptsSection.do.title',
  doTips: [
    {
      tip: 'getStartedPage.howWritePromptsSection.doTips.tip_one',
      example: 'getStartedPage.howWritePromptsSection.doTips.tip_one_example',
    },
    {
      tip: 'getStartedPage.howWritePromptsSection.doTips.tip_two',
      example: 'getStartedPage.howWritePromptsSection.doTips.tip_two_example',
    },
    {
      tip: 'getStartedPage.howWritePromptsSection.doTips.tip_three',
      example: 'getStartedPage.howWritePromptsSection.doTips.tip_three_example',
    },
    {
      tip: 'getStartedPage.howWritePromptsSection.doTips.tip_four',
      example: null,
    },
  ],
  dontHeading: 'getStartedPage.howWritePromptsSection.dont.title',
  dontTips: [
    'getStartedPage.howWritePromptsSection.dontTips.tip_one',
    'getStartedPage.howWritePromptsSection.dontTips.tip_two',
  ],
};

const classes = {
  container: 'margin-top-25',
  panel: 'panel panel-default panel-body panel-blank position-relative padding-x-25',
  sectionTitle: 'lead',
  iconList: 'icon-list margin-bottom-0',
  successIcon: 'rioglyph rioglyph-ok-sign text-color-success position-relative bottom-2',
  successIconDo: 'rioglyph rioglyph-ok-sign text-color-success margin-right-10 text-size-18 position-relative top-2',
  dangerIcon: 'rioglyph rioglyph-remove-sign text-color-danger position-relative bottom-3',
  flexColumn: 'display-flex flex-column gap-5',
};

const HowWritePromptsSection = () => {
  const dispatch = useDispatch();
  const pageTopRef = useRef<HTMLDivElement>(null);

  const handlePreviousSection = () => dispatch(setCurrentPage('page3'));
  const handleNextSection = () => dispatch(setCurrentPage('page5'));

  useEffect(() => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className={classes.container}>
      <div ref={pageTopRef} style={{ position: 'absolute', top: '0' }} />
      <div className={classes.panel}>
        <div>
          <div className={classes.sectionTitle}>
            <FormattedMessage id={promptText.tipsHeading} />
          </div>
          <ul className={classes.iconList}>
            {promptText.tips.map((tip, index) => (
              <li key={index}>
                <span className={classes.successIcon} />
                <span>
                  <FormattedMessage id={tip} />
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={classes.panel}>
        <div>
          <div className={classes.sectionTitle}>
            <FormattedMessage id={promptText.doHeading} />
          </div>
          <ul className={classes.iconList}>
            {promptText.doTips.map((tipItem, index) => (
              <li key={index} className={classes.flexColumn}>
                <div>
                  <span className={classes.successIconDo} />
                  <span>
                    <FormattedMessage id={tipItem.tip} />
                  </span>
                </div>
                {tipItem.example && (
                  <QuoteContainer>
                    <Quote>
                      <FormattedMessage id={tipItem.example} />
                    </Quote>
                  </QuoteContainer>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={classes.panel}>
        <div>
          <div className={classes.sectionTitle}>
            <FormattedMessage id={promptText.dontHeading} />
          </div>
          <ul className={classes.iconList}>
            {promptText.dontTips.map((tip, index) => (
              <li key={index}>
                <span className={classes.dangerIcon} />
                <span>
                  <FormattedMessage id={tip} />
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={classes.panel}>
        <PageNavigation
          onNext={handleNextSection}
          onPrevious={handlePreviousSection}
          previousLabel="getStartedPage.howVirtualAssistantWorks.label"
          nextLabel="getStartedPage.tools.label"
        />
      </div>
    </div>
  );
};

export default HowWritePromptsSection;

const QuoteContainer = styled.div`
  margin-top: 1rem;
  background-color: var(--gray-lightest);
  padding: 1rem;
`;

const Quote = styled.blockquote`
  margin: 0;
  font-style: italic;
  font-size: 1.4rem;
  color: var(--gray-darkest);
`;
