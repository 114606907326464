import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { nanoid } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { getUserProfile } from '../../../configuration/login/loginSlice';
import { useSubmitFeedbackMutation } from '../../../services/feedbackApi/feedback';
import { Feedback } from '../../../store/feedback/feedbackSlice';
import { getUserId } from '../../../utils/getUserId';
import StarRating from '../../molecule/StarRating/StarRating';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { SuccessMessage } from '../SuccessMessage/SuccessMessage';

type FeedbackDataProps = {
  rate: number | 0;
  improvementSuggestions: string;
  additionalComments: string;
};

const FeedbackForm = () => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm<FeedbackDataProps>({ mode: 'onChange' });
  const intl = useIntl();

  const [submitFeedback, { error, isLoading }] = useSubmitFeedbackMutation();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [formKey, setFormKey] = useState(0);
  const user = useSelector(getUserProfile);
  const userId = getUserId(user?.sub ?? '');

  const handleSuccessDialogClose = () => setShowSuccessDialog(false);

  const handleErrorDialogClose = () => setShowErrorDialog(false);

  const validateInput = (value: string) => {
    const specialCharacterRegex = /^[^{}<>]*$/;
    specialCharacterRegex.test(value);
    value.trim();

    if (!specialCharacterRegex.test(value)) {
      return intl.formatMessage({ id: 'common.feedback.validation.empty.error' });
    }

    if (value.length > 300) {
      return intl.formatMessage({ id: 'common.feedback.validation.tooLong.error' });
    }

    return true;
  };

  const onSubmit = async (data: FeedbackDataProps) => {
    try {
      let detail = '';

      if (data.additionalComments.length !== 0 || data.improvementSuggestions.length !== 0) {
        detail = `${data.improvementSuggestions} ${data.additionalComments}`;
      }
      const feedbackData: Feedback = {
        id: nanoid(),
        rate: data.rate,
        detail,
        userId,
      };
      const response = await submitFeedback(feedbackData);
      if (response.data) {
        setShowSuccessDialog(true);
        reset();
        setFormKey((prevKey) => prevKey + 1);
      } else {
        setRequestError(`${intl.formatMessage({ id: 'common.feedback.error.notification' })} ${response.error.error}`);
        setShowErrorDialog(true);
      }
    } catch (e: any) {
      setRequestError(e.message);
      setShowErrorDialog(true);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && isValid) {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="panel panel-default panel-body shadow-default">
      <form className="padding-x-25 padding-y-5" onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <h3 className="text-size-h3 text-bold">
          <FormattedMessage id="feedbackPage.feedbackForm.title" />
        </h3>
        <p className="text-color-dark">
          <FormattedMessage id="feedbackPage.feedbackForm.appeal" />
        </p>
        <div className="form-group">
          <span>
            <FormattedMessage id="feedbackPage.feedbackForm.question_one.label" />
          </span>
          <Controller
            name="rate"
            control={control}
            key={formKey}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <StarRating
                {...field}
                onChange={(rating) => {
                  field.onChange(rating);
                }}
              />
            )}
          />
        </div>
        <div className="form-group">
          <span>
            <FormattedMessage id="feedbackPage.feedbackForm.question_two.label" />
          </span>
          <div className="padding-y-5">
            <textarea
              className="form-control"
              id="improvementSuggestions"
              spellCheck="false"
              rows="3"
              placeholder={intl.formatMessage({ id: 'common.feedback.comment.placeholder' })}
              {...register('improvementSuggestions', {
                validate: validateInput,
              })}
              maxLength={300}
            />
            {errors.improvementSuggestions && (
              <span className="help-block text-color-danger">
                <span>{errors.improvementSuggestions.message}</span>
              </span>
            )}
          </div>
        </div>
        <div className="form-group">
          <span>
            <FormattedMessage id="feedbackPage.feedbackForm.additional_comment.label" />
          </span>
          <div className="padding-y-5">
            <textarea
              className="form-control"
              id="additionalComments"
              spellCheck="false"
              rows="3"
              placeholder={intl.formatMessage({ id: 'common.feedback.comment.placeholder' })}
              {...register('additionalComments', {
                validate: validateInput,
              })}
              maxLength={300}
            />
            {errors.additionalComments && (
              <>
                <span className="help-block text-color-danger">
                  <span>{errors.additionalComments.message}</span>
                </span>
              </>
            )}
          </div>
        </div>
        <div className="display-flex justify-content-end">
          <button type="submit" disabled={isLoading || !isValid} className="btn btn-primary">
            <FormattedMessage
              id={isLoading ? 'feedbackPage.feedbackForm.submiting.label' : 'common.feedback.submit.label'}
            />
          </button>
        </div>
      </form>
      {showSuccessDialog && (
        <SuccessMessage
          show={showSuccessDialog}
          handleClose={handleSuccessDialogClose}
          message="common.feedback.success.notification"
        />
      )}
      {showErrorDialog && (
        <ErrorMessage show={showErrorDialog} handleClose={handleErrorDialogClose} message={requestError} />
      )}
    </div>
  );
};

export default FeedbackForm;
