import { ChangeEvent, KeyboardEventHandler, useState } from 'react';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentService, Service, setCurrentService } from '../../../store/service/serviceSlice';
import Select, { SelectOption } from '@rio-cloud/rio-uikit/components/selects/Select';
import styled from 'styled-components';
import { selectActiveThreadId } from '../../../store/thread/threadSlice';
import { useIsDemoThread } from '../../../hooks/useIsDemoThread';

type ChatInputProps = {
  isLoading: boolean;
  onSendMessage: (message: string) => void;
  onSendFile?: (file: File) => void;
};

const validationRules = {
  maxMessageLength: 300,
  allowedFileType: 'application/pdf',
  maxFileSizeMB: 5,
};

const errorMessages = {
  emptyMessage: 'chatPage.chatInput.validation.empty.error',
  messageTooLong: 'chatPage.chatInput.validation.tooLong.error',
  invalidFileType: 'chatPage.chatInput.validation.invalidType.error',
  fileTooLarge: 'chatPage.chatInput.validation.tooLarge.error',
};

type ServiceToLabel = {
  [key in Service]?: string;
};

const serviceToLabel: ServiceToLabel = {
  perform: 'getStartedPage.common.performanceAnalysis.label',
  training: 'getStartedPage.common.manTraining.label',
};

const ChatInput = ({ isLoading, onSendMessage, onSendFile }: ChatInputProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentService = useSelector(selectCurrentService);
  const currentThreadId = useSelector(selectActiveThreadId);
  const isDemoThread = useIsDemoThread(currentThreadId);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const showError = (errorMessage: string) => {
    setError(errorMessage);
    Notification.error(<span>{errorMessage}</span>);
  };

  const validateFile = (file: File): boolean => {
    if (file.type !== validationRules.allowedFileType) {
      showError(intl.formatMessage({ id: errorMessages.invalidFileType }));
      return false;
    }
    if (file.size > validationRules.maxFileSizeMB * 1024 * 1024) {
      showError(
        intl.formatMessage(
          {
            id: errorMessages.fileTooLarge,
          },
          { maxFileSizeMB: validationRules.maxFileSizeMB },
        ),
      );
      return false;
    }
    return true;
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const file = target.files[0];

      if (validateFile(file)) {
        onSendFile(file);
      }
      target.value = '';
    } else {
      const sanitizedValue = sanitizeInput(target.value.slice(0, validationRules.maxMessageLength));
      setInputValue(sanitizedValue);
      if (error) {
        setError('');
      }
    }
  };

  const sanitizeInput = (value: string) => value.replace(/[<>{}]/g, '');

  const validateInput = (value: string) => {
    if (!value.trim()) {
      showError(intl.formatMessage({ id: errorMessages.emptyMessage }));
      return false;
    }
    if (value.length > validationRules.maxMessageLength) {
      showError(
        intl.formatMessage(
          {
            id: errorMessages.messageTooLong,
          },
          { maxMessageLength: validationRules.maxMessageLength },
        ),
      );
      return false;
    }
    return true;
  };

  const handleSend = () => {
    if (validateInput(inputValue)) {
      onSendMessage(inputValue);
      setInputValue('');
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter' && !isLoading) {
      handleSend();
    }
  };

  const handleChangeService = (selectOption: SelectOption | undefined) => {
    const selectedService =
      selectOption &&
      (Object.keys(serviceToLabel) as Service[]).find(
        (key) => intl.formatMessage({ id: serviceToLabel[key] }) === selectOption.label,
      );

    if (selectedService) {
      dispatch(setCurrentService(selectedService));
    }
  };

  const serviceOptions = [
    {
      id: '1',
      className: 'width-300',
      label: intl.formatMessage({ id: serviceToLabel['perform'] }),
      icon: <span className="rioglyph rioglyph-stats text-color-primary" />,
      selected: currentService === 'perform',
    },
    {
      id: '2',
      label: intl.formatMessage({ id: serviceToLabel['training'] }),
      icon: <span className="rioglyph rioglyph-book text-color-primary" />,
      selected: currentService === 'training',
    },
  ];

  return (
    <div className="input-group width-100pct">
      <input
        type="text"
        className="form-control"
        placeholder={intl.formatMessage({ id: 'chatPage.chatInput.placeholder' })}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        maxLength={validationRules.maxMessageLength}
        style={{ borderRadius: '0 4px 4px 0' }}
      />
      <div className="input-group-prepend">
        <StyledSelect
          options={serviceOptions}
          showSelectedItemIcon
          dropdownClassName="dropdown-in-input"
          onChange={handleChangeService}
          data-testid="service-select"
        />
      </div>
      <span className="input-group-btn">
        <button
          className="btn btn-primary"
          type="button"
          data-testid="send-button"
          onClick={handleSend}
          disabled={!inputValue.trim() || isLoading || isDemoThread}
        >
          <span className="rioglyph rioglyph-send" />
        </button>
      </span>
    </div>
  );
};

export default ChatInput;

const StyledSelect = styled(Select)`
  & > button {
    border-radius: 0;
    border-left: none;
  }
  & > ul {
    min-width: 200px;
  }
`;
