import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { useIntl } from 'react-intl';
import OnboardingTip from '../OnboardingTip/OnboardingTip';
import {
  selectShowTips,
  setShowTips,
  selectSteps,
  selectCurrentStep,
  setCurrentStep,
  resetSteps,
} from '../../../store/tips/tipsSlice';

const OnboardingWalkthrough = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const showTips = useSelector(selectShowTips);
  const steps = useSelector(selectSteps);
  const currentStep = useSelector(selectCurrentStep);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      dispatch(setCurrentStep(currentStep - 1));
    }
  };

  const handleClose = () => {
    dispatch(setShowTips(false));
    dispatch(resetSteps());
  };

  if (!showTips) return null;

  const buttonNextText = intl.formatMessage({
    id: 'chatPage.sidebar.onboardingTip.button.next',
  });
  const buttonPreviousText = intl.formatMessage({
    id: 'chatPage.sidebar.onboardingTip.button.previous',
  });
  const buttonFinish = intl.formatMessage({
    id: 'chatPage.sidebar.onboardingTip.button.finish',
  });

  return (
    <Overlay>
      <AnimatedOnboardingTip
        key={currentStep}
        show={showTips}
        stepIndex={currentStep}
        title={intl.formatMessage({ id: steps[currentStep].title })}
        content={intl.formatMessage({ id: steps[currentStep].content })}
        previousButton={
          currentStep > 0
            ? {
                text: buttonPreviousText,
                iconName: 'rioglyph-arrow-left',
                onClick: handlePrevious,
              }
            : undefined
        }
        nextButton={
          currentStep < steps.length - 1
            ? {
                text: buttonNextText,
                iconName: 'rioglyph-arrow-right',
                onClick: handleNext,
              }
            : {
                text: buttonFinish,
                onClick: handleClose,
              }
        }
      />
    </Overlay>
  );
};

export default OnboardingWalkthrough;

const fadeSlideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  margin-left: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
`;

const AnimatedOnboardingTip = styled(OnboardingTip)`
  animation: ${fadeSlideIn} 1s ease-out;
`;
