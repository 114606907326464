import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import IframeResizer from 'iframe-resizer-react';
import { config } from '../../../config';
import ServiceInfo from '../ServiceInfo/ServiceInfo';

const Header = () => {
  const environment = import.meta.env.PROD ? 'production' : 'local';

  const serviceInfoItem = <ServiceInfo />;
  const notifications = <RioNotifications />;
  const userMenuItem = <DefaultUserMenu environment={environment} />;
  return (
    <ApplicationHeader
      label={<FormattedMessage id="common.app.title" defaultMessage="Fleet Assistant" />}
      appNavigator={<IframeResizer className="iFrameResizer" src={config.backend.MENU_SERVICE} />}
      homeRoute={<Link to={config.homeRoute || ''} />}
      navItems={[]}
      actionBarItems={[serviceInfoItem, notifications, userMenuItem]}
    />
  );
};

export default Header;
