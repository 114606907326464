import { FormattedMessage } from 'react-intl';
import { ChatThread } from '../../../store/thread/threadSlice';
import ThreadListItem from '../../organism/ThreadListItem/ThreadListItem';

type ChatThreadCategoryProps = {
  title: string;
  threads: ChatThread[];
};

const ChatThreadCategory = ({ title, threads }: ChatThreadCategoryProps) => (
  <div>
    <p className="text-bold padding-x-10">
      <FormattedMessage id={title} />
    </p>
    <ul className="overflow-auto list-group padding-0">
      {threads.map((thread) =>
        thread.title ? (
          <ThreadListItem
            key={`item-${thread.id}`}
            threadId={thread.id}
            firstMessage={thread.title}
            isFavorite={thread.isFavorite}
          />
        ) : null,
      )}
    </ul>
  </div>
);

export default ChatThreadCategory;
