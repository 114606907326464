import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ChatThread } from '../../store/thread/threadSlice';
import { prepareHeaders } from '../../utils/prepareHeaders';
import { Response } from '../../utils/types';
import { Message, Role } from '../../store/chat/chat.slice';

type ThreadRequest = {
  userId: string;
  threadId?: string;
};

export type ThreadDataResponse = {
  title: string;
  thread_id: string;
  last_updated: string;
  favorite: boolean;
};

export type MessageDataResponse = {
  message_id: string;
  role: Role;
  content: string;
  last_updated: string;
};

export type MessageResponse = {
  messages: MessageDataResponse[];
  thread_id: string;
};

export const threadApi = createApi({
  reducerPath: 'threadApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${import.meta.env.VITE_BASE_BACKEND_URL}/chat`, prepareHeaders }),
  endpoints: (builder) => ({
    fetchThreads: builder.query<Response<ChatThread[]>, ThreadRequest>({
      query: ({ userId }) => `/${userId}/threads`,
      transformResponse: (response: Response<ThreadDataResponse[]>) => {
        return {
          ...response,
          data: response.data
            ?.map((thread) => ({
              ...thread,
              id: thread.thread_id,
              timestamp: thread.last_updated,
              isFavorite: thread.favorite,
            }))
            .map(({ thread_id, last_updated, favorite, ...rest }) => rest),
        };
      },
    }),
    deleteThread: builder.mutation<Response, ThreadRequest>({
      query: ({ userId, threadId }) => ({
        url: `/${userId}/threads/${threadId}`,
        method: 'DELETE',
      }),
    }),
    fetchMessagesByThreadId: builder.query<Message[], ThreadRequest>({
      query: ({ userId, threadId }) => `/${userId}/messages/${threadId}`,
      transformResponse: (response: Response<MessageResponse>) => {
        const transformedMessages = response.data?.messages.map(({ message_id, last_updated, ...rest }) => ({
          ...rest,
          threadId: response.data?.thread_id,
          id: message_id,
          timestamp: last_updated,
        }));
        return transformedMessages;
      },
    }),
    updateThread: builder.mutation<Response, ThreadRequest & { updateData: Partial<ThreadDataResponse> }>({
      query: ({ userId, threadId, updateData }) => ({
        url: `/${userId}/threads/${threadId}`,
        method: 'PATCH',
        body: updateData,
      }),
    }),
  }),
});

export const {
  useFetchThreadsQuery,
  useDeleteThreadMutation,
  useFetchMessagesByThreadIdQuery,
  useUpdateThreadMutation,
} = threadApi;
