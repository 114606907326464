import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LayoutProps, LayoutItem, sidebarToContent } from './sidebarToContent';

type LayoutState = {
  activeLayout: LayoutItem;
  activeLayoutProps: LayoutProps;
  showWelcomeAnimation: boolean;
};

const loadLayoutStateFromLocalStorage = (): LayoutState => {
  try {
    const savedLayout = localStorage.getItem('layoutState');
    const isFirstLogin = localStorage.getItem('isFirstLogin') === null;

    if (isFirstLogin) {
      localStorage.setItem('isFirstLogin', 'false');
      return {
        activeLayout: 'onboardingMode',
        activeLayoutProps: sidebarToContent['onboardingMode'],
        showWelcomeAnimation: true,
      };
    }

    if (savedLayout) {
      const parsedLayout = JSON.parse(savedLayout);
      return {
        activeLayout: parsedLayout.activeLayout,
        activeLayoutProps: sidebarToContent[parsedLayout.activeLayout],
        showWelcomeAnimation: false,
      };
    }

    return {
      activeLayout: 'chatHistory',
      activeLayoutProps: sidebarToContent['chatHistory'],
      showWelcomeAnimation: false,
    };
  } catch (e) {
    console.error('Failed to load layout state from localStorage:', e);
  }

  return {
    activeLayout: 'chatHistory',
    activeLayoutProps: sidebarToContent['chatHistory'],
    showWelcomeAnimation: false,
  };
};

const initialState: LayoutState = loadLayoutStateFromLocalStorage();

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setCurrentLayout: (state, action: PayloadAction<LayoutItem>) => {
      state.activeLayout = action.payload;
      state.activeLayoutProps = sidebarToContent[action.payload];

      localStorage.setItem('layoutState', JSON.stringify({ activeLayout: state.activeLayout }));
    },
    disableWelcomeAnimation: (state) => {
      state.showWelcomeAnimation = false;
    },
    resetToOnboarding: (state) => {
      state.activeLayout = 'onboardingMode';
      state.activeLayoutProps = sidebarToContent['onboardingMode'];
      localStorage.setItem('layoutState', JSON.stringify({ activeLayout: 'onboardingMode' }));
    },
  },
});

export const { setCurrentLayout, disableWelcomeAnimation, resetToOnboarding } = layoutSlice.actions;

export default layoutSlice.reducer;

export const selectCurrentLayout = (state: { layout: LayoutState }) => state.layout.activeLayout;

export const selectCurrentLayoutProps = (state: { layout: LayoutState }) => state.layout.activeLayoutProps;

export const selectShowWelcomeAnimation = (state: { layout: LayoutState }) => state.layout.showWelcomeAnimation;
