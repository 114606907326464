import { Component, type ErrorInfo, type ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import { FormattedMessage } from 'react-intl';

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<{ children?: ReactNode }, State> {
  constructor(props: { children?: ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.withScope((scope) => {
      scope.setExtra('componentStack', errorInfo.componentStack);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <p>
          <FormattedMessage id="common.error" />
        </p>
      );
    }

    return this.props.children;
  }
}
