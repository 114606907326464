export const pages = ['page1', 'page2', 'page3', 'page4', 'page5'] as const;
const components = [
  'welcomePage',
  'fleetAssistantOverview',
  'assistantFunctionality',
  'promptWritingGuide',
  'toolsOverview',
] as const;

export type Page = (typeof pages)[number];

export type Component = (typeof components)[number];

export type PageProps = {
  translation: string;
  componentId: Component;
};

export type PageToContent = {
  [key in Page]: PageProps;
};

export type ComponentToElement = {
  [key in Component]: JSX.Element;
};

export const pageToContent: PageToContent = {
  page1: {
    translation: 'getStartedPage.welcome.label',
    componentId: 'welcomePage',
  },
  page2: {
    translation: 'getStartedPage.whatIsFleetAssistant.label',
    componentId: 'fleetAssistantOverview',
  },
  page3: {
    translation: 'getStartedPage.howVirtualAssistantWorks.label',
    componentId: 'assistantFunctionality',
  },
  page4: {
    translation: 'getStartedPage.howToWritePrompts.label',
    componentId: 'promptWritingGuide',
  },
  page5: {
    translation: 'getStartedPage.tools.label',
    componentId: 'toolsOverview',
  },
} as const;
