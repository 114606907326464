import { useSelector } from 'react-redux';
import ChatThreadCategory from '../../molecule/ChatThreadCategory/ChatThreadCategory';
import { getUserProfile } from '../../../configuration/login/loginSlice';
import { useFetchThreadsQuery } from '../../../services/threadApi/threadApi';
import { getUserId } from '../../../utils/getUserId';
import { selectFavoriteThreads } from '../../../store/thread/threadSlice';
import { useIntl } from 'react-intl';
import SearchField from '../../atom/SearchField/SearchField';
import { useEffect, useState } from 'react';

const FavoritesThreadsList = () => {
  const intl = useIntl();
  const user = useSelector(getUserProfile);
  const { data } = useFetchThreadsQuery(
    { userId: getUserId(user?.sub ?? '') },
    { refetchOnMountOrArgChange: true, skip: false },
  );
  const favoriteThreads = useSelector(selectFavoriteThreads);
  const [threadsList, setThreadsList] = useState(favoriteThreads);

  const handleSearch = (searchValue: string) => {
    const filteredLists = favoriteThreads.filter((item) =>
      (item.title as string).toLowerCase().includes(searchValue.toLowerCase()),
    );
    setThreadsList(filteredLists);
  };

  useEffect(() => {
    setThreadsList(favoriteThreads);
  }, [favoriteThreads]);

  return (
    <div className="padding-20">
      {favoriteThreads && favoriteThreads.length > 0 ? (
        <div>
          <SearchField onSearch={handleSearch} />
          <ChatThreadCategory
            title={intl.formatMessage({ id: 'chatPage.chatThreadList.title' })}
            threads={threadsList}
          />
        </div>
      ) : (
        <p>{intl.formatMessage({ id: 'chatPage.chatThreadList.threadAddToFavorites.noFavorites.label' })}</p>
      )}
    </div>
  );
};

export default FavoritesThreadsList;
