import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Feedback } from '../../store/feedback/feedbackSlice';
import { mapFeedbackToRequest } from '../../utils/mappers/feedbackMapper';
import { prepareHeaders } from '../../utils/prepareHeaders';

export type FeedbackResponse = {
  content: string;
};

export type FeedbackRequest = {
  rating?: string;
  detailed_answer?: string;
  last_question?: string | null;
  last_answer?: string | null;
  user_id: string;
};

export const feedbackApi = createApi({
  reducerPath: 'feedbackApi',
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_BASE_BACKEND_URL, prepareHeaders }),
  endpoints: (build) => ({
    submitFeedback: build.mutation<FeedbackResponse, Feedback>({
      query: (feedback): { url: string; method: string; body: FeedbackRequest } => ({
        url: 'feedback/',
        method: 'POST',
        body: mapFeedbackToRequest(feedback),
      }),
    }),
  }),
});

export const { useSubmitFeedbackMutation } = feedbackApi;
