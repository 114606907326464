import { Middleware } from '@reduxjs/toolkit';

const sessionExpiryMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  if (action.payload?.status === 401) {
    alert('Your session has expired, please refresh the page.');
  }
  return next(action);
};

export default sessionExpiryMiddleware;
