import { FormattedMessage } from 'react-intl';

type ToolCardProps = {
  title: string;
  imageName: string;
  description: string;
};

const ToolCard = ({ title, imageName, description }: ToolCardProps) => (
  <div className="margin-10 position-relative">
    <div
      className="panel panel-default panel-body shadow-smooth padding-25 height-350
    display-flex flex-column justify-content-start"
    >
      <div className="text-center text-color-darker margin-top-15">
        <div className="text-size-15 padding-15">
          <img src={`assets/images/${imageName}.svg`} alt="Hero" height="140" />
        </div>
        <div className="text-size-12">
          <div className="text-size-200pct text-capitalize text-medium">
            <FormattedMessage id={title} />
          </div>
        </div>
        <p className="margin-bottom-20 text-color-dark">
          <FormattedMessage id={description} />
        </p>
      </div>
    </div>
  </div>
);

export default ToolCard;
