import { Fragment, useEffect, useRef, useState } from 'react';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ChatAssetTree from '../../atom/ChatAssetTree/ChatAssetTree';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentLayout } from '../../../store/layout/layoutSlice';
import AboutNavigation from '../../atom/AboutNavigation/AboutNavigation';
import FrequentlyAskedQuestionsList from '../../molecule/FrequentlyAskedQuestionsList/FrequentlyAskedQuestionsList';
import ChatHistoryList from '../../organism/ChatHistoryList/ChatHistoryList';
import FavoritesThreadsList from '../../organism/FavoritesThreadsList/FavoritesThreadsList';
import ChatWindow from '../ChatWindow/ChatWindow';
import GetStartedPage from '../GetStarted/GetStartedPage';
import FeedbackPage from '../FeedbackPage/FeedbackPage';
import FeedbackInfoSidebar from '../../organism/FeedbackInfoSidebar/FeedbackInfoSidebar';
import OnboardingIntroductionContent from '../../organism/OnboardingIntroductionContent/OnboardingIntroductionContent';
import { selectCurrentService, Service, setCurrentService } from '../../../store/service/serviceSlice';
import {
  activateOnboardingMode,
  handleNextOnboardingStep,
  selectIsOnboardingMode,
  selectOnboardingStep,
  Steps,
} from '../../../store/onboarding/onboardingSlice';
import { getLocale } from '../../../configuration/lang/langSlice';
import {
  addLoadingThreadId,
  removeLoadingThreadId,
  selectActiveThreadId,
  updateThreadTitle,
} from '../../../store/thread/threadSlice';
import { addMessage, setLoadingMessage } from '../../../store/chat/chat.slice';
import { getUserId } from '../../../utils/getUserId';
import { getUserProfile } from '../../../configuration/login/loginSlice';
import { useSendMessageMutation } from '../../../services/chatApi/chatApi';
import { useIntl } from 'react-intl';
import SettingsPanel from '../../organism/SettingsPanel/SettingsPanel';
import OnboardingWalkthrough from '../../organism/OnboardingWalkthrough/OnboardingWalkthrough';
import { openSidebar } from '../../../store/sidebar/sidebarSlice';

export const componentMap = {
  chatHistory: {
    sidebarComponent: ChatHistoryList,
    mainComponent: ChatWindow,
  },
  favorites: {
    sidebarComponent: FavoritesThreadsList,
    mainComponent: ChatWindow,
  },
  faq: {
    sidebarComponent: FrequentlyAskedQuestionsList,
    mainComponent: ChatWindow,
  },
  about: {
    sidebarComponent: AboutNavigation,
    mainComponent: GetStartedPage,
  },
  feedback: {
    sidebarComponent: FeedbackInfoSidebar,
    mainComponent: FeedbackPage,
  },
  onboardingMode: {
    sidebarComponent: OnboardingIntroductionContent,
    mainComponent: ChatWindow,
  },
  settings: {
    sidebarComponent: SettingsPanel,
    mainComponent: ChatWindow,
  },
} as const;

const ChatPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activePage = useSelector(selectCurrentLayout);
  const currentLanguage = useSelector(getLocale);
  const currentThreadId = useSelector(selectActiveThreadId);
  const currentService = useSelector(selectCurrentService);
  const onboardingStep = useSelector(selectOnboardingStep);
  const isOnboardingMode = useSelector(selectIsOnboardingMode);
  const user = useSelector(getUserProfile);
  const userId = getUserId(user?.sub ?? '');
  const [sendMessage, { isError, isSuccess, ...rest }] = useSendMessageMutation();
  const currentThreadIdRef = useRef(currentThreadId);

  const handleSend = async (
    content: string,
    service?: Service,
    isOnboarding: boolean = false,
    nextStepIndex?: Steps,
  ) => {
    const threadId = currentThreadIdRef.current;
    if (isOnboarding) dispatch(handleNextOnboardingStep());

    if (service) dispatch(setCurrentService(service));

    const requestedMessage = {
      service,
      question: content,
      language: currentLanguage === 'de-DE' ? 'Deutsch' : 'English',
      threadId,
      meta: { isOnboarding, currentLanguage, onboardingStep, nextStepIndex },
      ...((service === 'training' || currentService === 'training') && { use_temp_db: false }),
      userId,
    };

    dispatch(
      addMessage({
        content,
        role: 'human',
        threadId,
        timestamp: new Date().toISOString(),
        service: service || currentService,
        isOnboardingQuestion: false,
      }),
    );

    dispatch(updateThreadTitle({ title: content, threadId }));
    try {
      dispatch(addLoadingThreadId(threadId));
      dispatch(setLoadingMessage(true));
      await sendMessage(requestedMessage).unwrap();
    } catch (err) {
      console.error('Failed to send the message: ', err);
    } finally {
      dispatch(removeLoadingThreadId(threadId));
    }
  };

  useEffect(() => {
    if (isError) {
      const errorMessage = rest?.error?.error ?? intl.formatMessage({ id: 'common.error' });
      Notification.error(errorMessage);
      dispatch(
        addMessage({
          content: errorMessage,
          role: 'ai',
          threadId: currentThreadId,
          timestamp: new Date().toISOString(),
          service: currentService,
          isOnboardingQuestion: false,
        }),
      );
    }
    if (isSuccess) {
      const response = rest?.data;
      if (response?.status === 'failure') {
        const failureMessage = response?.message || intl.formatMessage({ id: 'common.error' });
        dispatch(
          addMessage({
            content: failureMessage,
            role: 'ai',
            threadId: currentThreadId,
            timestamp: new Date().toISOString(),
            service: currentService,
            isOnboardingQuestion: false,
          }),
        );
      }
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    currentThreadIdRef.current = currentThreadId;
  }, [currentThreadId]);

  useEffect(() => {
    if (isOnboardingMode) {
      dispatch(openSidebar());
    }
  }, [isOnboardingMode, dispatch]);

  useEffect(() => {
    const isFirstLogin = localStorage.getItem('isFirstLogin') === null;

    if (isFirstLogin) {
      dispatch(activateOnboardingMode());
      localStorage.setItem('isFirstLogin', 'false');
    }
  }, [dispatch]);

  const MainComponent = componentMap[activePage].mainComponent;

  return (
    <Fragment>
      <ChatAssetTree handleSend={handleSend} />
      <ApplicationLayout.Body>
        <MainComponent handleSend={handleSend} />
        <OnboardingWalkthrough />
      </ApplicationLayout.Body>
    </Fragment>
  );
};

export default ChatPage;
