import Switch from '@rio-cloud/rio-uikit/components/switch/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectIsOnboardingMode, toggleOnboardingMode } from '../../../store/onboarding/onboardingSlice';
import { selectShowTips, toggleTips } from '../../../store/tips/tipsSlice';

const SettingsPanel = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const isOnboardingMode = useSelector(selectIsOnboardingMode);
  const showTips = useSelector(selectShowTips);

  const handleToggleOnboarding = () => {
    dispatch(toggleOnboardingMode());
  };

  const handleToggleTips = () => {
    dispatch(toggleTips());
  };

  return (
    <div className="padding-20 display-flex flex-column justify-content-start height-100pct gap-10">
      <p className="margin-top-0 margin-bottom-20 text-size-16 text-bold">
        {intl.formatMessage({ id: 'common.settings.title' })}
      </p>

      <div className="display-flex gap-10">
        <Switch onChange={handleToggleOnboarding} checked={isOnboardingMode} />
        <label className="text-size-14">
          {intl.formatMessage({
            id: isOnboardingMode
              ? 'common.settings.deactivateOnboardingMode.label'
              : 'common.settings.activateOnboardingMode.label',
          })}
        </label>
      </div>

      <div className="display-flex gap-10">
        <Switch onChange={handleToggleTips} checked={showTips} />
        <label className="text-size-14">
          {intl.formatMessage({ id: showTips ? 'common.settings.hideTips.label' : 'common.settings.showTips.label' })}
        </label>
      </div>
    </div>
  );
};

export default SettingsPanel;
