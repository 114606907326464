import Button from '@rio-cloud/rio-uikit/Button';
import useDarkMode from '@rio-cloud/rio-uikit/hooks/useDarkMode';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setCurrentLayout } from '../../../store/layout/layoutSlice';

const texts = {
  title: 'getStartedPage.getStartedSection.title',
  description: 'getStartedPage.getStartedSection.description',
  startChatLabel: 'getStartedPage.common.startChat.label',
};

const GetStartedSection = () => {
  const isDarkMode = useDarkMode();
  const dispatch = useDispatch();

  const handleStartConversation = () => dispatch(setCurrentLayout('chatHistory'));
  return (
    <Container isDarkMode={isDarkMode}>
      <TextContainer>
        <Heading isDarkMode={isDarkMode}>
          <FormattedMessage id={texts.title} />
        </Heading>
        <Text isDarkMode={isDarkMode}>
          <FormattedMessage id={texts.description} />
        </Text>
        <Button bsStyle={Button.PRIMARY} onClick={handleStartConversation}>
          <FormattedMessage id={texts.startChatLabel} />
        </Button>
      </TextContainer>
      <ImageContainer>
        <img src="assets/images/screenshot.png" alt="" height={200} />
      </ImageContainer>
    </Container>
  );
};

export default GetStartedSection;

const Container = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  padding: 4rem;
  background: ${(props) => (!props.isDarkMode ? 'var(--gray-darkest)' : 'var(--color-white)')};
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  gap: 3rem;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    padding: 1rem;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding-right: 3rem;

  @media (max-width: 1024px) {
    padding-right: 0;
    text-align: center;
  }
`;

const Heading = styled.h3<{ isDarkMode: boolean }>`
  margin-bottom: 1rem;
  text-align: left;
  color: ${(props) => (props.isDarkMode ? 'var(--gray-darkest)' : 'var(--color-white)')};
  @media (max-width: 1024px) {
    text-align: center;
  }
`;

const Text = styled.p<{ isDarkMode: boolean }>`
  line-height: 1.5;
  max-width: 500px;
  color: ${(props) => (props.isDarkMode ? 'var(--color-black)' : 'var(--color-white)')};
`;

const ImageContainer = styled.div`
  flex: 1;
  text-align: right;

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 1024px) {
    text-align: center;
    margin-bottom: 2rem;
  }
`;
