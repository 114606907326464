import { accessToken } from '../configuration/tokenHandling/accessToken';
import type { AccessToken } from '../configuration/tokenHandling/tokenSlice';

export const prepareHeaders = (headers: Headers) => {
  const token = accessToken.getAccessToken() as AccessToken;
  if (token) {
    headers.set('authorization', `Bearer ${token}`);
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
  }
  return headers;
};
