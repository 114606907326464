import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const texts = {
  performanceAnalysisLabel: 'getStartedPage.common.performanceAnalysis.label',
  performanceAnalysisDescription: 'getStartedPage.common.performanceAnalysis.description',
  manTrainingLabel: 'getStartedPage.common.manTraining.label',
  manTrainingDescription: 'getStartedPage.common.manTraining.description',
  tailoredFeaturesLabel: 'getStartedPage.featuresSection.tailoredFeatures.label',
  tailoredFeaturesSubLabel: 'getStartedPage.featuresSection.tailoredFeatures.sub.label',
};

const cards = [
  {
    title: texts.performanceAnalysisLabel,
    iconClass: 'rioglyph-stats',
    description: texts.performanceAnalysisDescription,
  },
  {
    title: texts.manTrainingLabel,
    iconClass: 'rioglyph-book',
    description: texts.manTrainingDescription,
  },
];

const FeaturesSection = () => (
  <Container>
    <Header>
      <h2>
        <FormattedMessage id={texts.tailoredFeaturesLabel} />
      </h2>
      <h3>
        <span>
          <FormattedMessage id={texts.tailoredFeaturesSubLabel} />
        </span>
      </h3>
    </Header>
    <CardsContainer>
      {cards.map((card, index) => (
        <Card key={index}>
          <CardContent>
            <IconContainer>
              <span className={`rioglyph ${card.iconClass}`} />
            </IconContainer>
            <Title>
              <FormattedMessage id={card.title} />
            </Title>
            <Description>
              <FormattedMessage id={card.description} />
            </Description>
          </CardContent>
        </Card>
      ))}
    </CardsContainer>
  </Container>
);

export default FeaturesSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  width: 100%;
  padding: 2rem 0;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Header = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  text-align: center;

  h2 {
    text-transform: uppercase;
  }

  h3 {
    margin-top: 1rem;

    span {
      color: var(--brand-primary);
    }
  }
`;

const CardsContainer = styled.div`
  display: flex !important;
  padding: 0 2rem;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const Card = styled.div`
  margin: 1rem;
  max-width: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardContent = styled.div`
  padding: 3rem;
  text-align: center;
`;

const IconContainer = styled.div`
  font-size: 3rem;
  padding: 2rem;
`;

const Title = styled.div`
  font-size: 2rem;
  padding-bottom: 1rem;
  text-transform: capitalize;
  font-weight: 600;
`;

const Description = styled.p`
  margin-bottom: 1.6rem;
`;
