import { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import {
  selectMessagesByThreadId,
  selectIsFetchingHistory,
  selectIsLoadingMessage,
} from '../../../store/chat/chat.slice';
import { Steps } from '../../../store/onboarding/onboardingSlice';
import { selectCurrentService, Service } from '../../../store/service/serviceSlice';
import { selectActiveThreadId, selectIsThreadLoading } from '../../../store/thread/threadSlice';
import ChatInput from '../../molecule/ChatInput/ChatInput';
import SafetyNotice from '../../organism/SafetyNotice/SafetyNotice';
import ServicesSection from '../../organism/ServicesSection/ServicesSection';
import SpeechBubble from '../../organism/SpeechBubble/SpeechBubble';
import ErrorStateMessage from '../../organism/ErrorStateMessage/ErrorStateMessage';
import WelcomeSection from '../../molecule/WelcomSection/WelcomeSection';

const SAFETY_NOTICE_KEY = 'hiddenSafetyNoticeUntil';
const DAYS_30 = 30 * 24 * 60 * 60 * 1000;

type ChatWindowProps = {
  handleSend?: (content: string, service?: Service, isOnboarding?: boolean, nextStepIndex?: Steps) => Promise<void>;
};

const ChatWindow = ({ handleSend }: ChatWindowProps) => {
  const intl = useIntl();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const currentService = useSelector(selectCurrentService);
  const currentThreadId = useSelector(selectActiveThreadId);
  const messages = useSelector(selectMessagesByThreadId(currentThreadId));
  const isThreadsLoading = useSelector(selectIsThreadLoading(currentThreadId));
  const isHistoryFetching = useSelector(selectIsFetchingHistory);
  const isLoading = useSelector(selectIsLoadingMessage);

  const [showSafetyNotice, setShowSafetyNotice] = useState(false);
  const [isSafetyNoticeChecked, setIsSafetyNoticeChecked] = useState(localStorage.getItem(SAFETY_NOTICE_KEY));

  const startDate = parseInt(localStorage.getItem(SAFETY_NOTICE_KEY) ?? '0', 10);
  const hiddenUntil = new Date(startDate).getTime();
  const now = Date.now();

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSetShow = (value: boolean) => setShowSafetyNotice(value);

  useEffect(() => {
    if (now - hiddenUntil > DAYS_30) {
      setShowSafetyNotice(true);
    }
  }, []);

  const handleCheckSafetyNoticeDate = () => setIsSafetyNoticeChecked((prev) => !prev);

  useEffect(() => {
    if (isSafetyNoticeChecked) {
      localStorage.setItem(SAFETY_NOTICE_KEY, Date.now().toString());
    }
  }, [isSafetyNoticeChecked]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, isLoading]);

  return (
    <ChatWindowWrapper>
      {showSafetyNotice ? (
        <SafetyNotice
          show={showSafetyNotice}
          handleOnChange={handleCheckSafetyNoticeDate}
          checked={isSafetyNoticeChecked}
          setShow={handleSetShow}
          onClose={() => setShowSafetyNotice(false)}
        />
      ) : null}
      <ContentWrapper>
        {!messages && (isThreadsLoading || isHistoryFetching) ? <Spinner /> : null}
        {messages.length === 0 && currentThreadId.length > 0 && !isThreadsLoading && !isHistoryFetching ? (
          <ErrorStateMessage message={intl.formatMessage({ id: 'chatPage.chatThreadList.error.noMessage' })} />
        ) : null}
        {messages.length === 0 && currentThreadId.length === 0 && !isThreadsLoading && (
          <Fragment>
            <WelcomeSection />
            <ServicesSection
              onClickQuestion={(message, service, isOnboarding) => handleSend(message, service, isOnboarding, 'step-1')}
            />
          </Fragment>
        )}
        <MessagesSection>
          {messages.map((msg, index) => (
            <SpeechBubble key={`speechBubble-${index}`} messageId={msg.id} handleSend={handleSend} />
          ))}
          {isThreadsLoading && isLoading ? (
            <div className=" min-height-200">
              <Spinner text={intl.formatMessage({ id: 'chatPage.processIndicator.label' })} />
            </div>
          ) : null}
          <div ref={messagesEndRef} style={{ marginBottom: '-0.5rem' }} />
        </MessagesSection>
      </ContentWrapper>
      <ChatInput
        isLoading={isThreadsLoading}
        onSendMessage={handleSend ? (message) => handleSend(message, currentService) : () => null}
      />
    </ChatWindowWrapper>
  );
};

export default ChatWindow;

const ChatWindowWrapper = styled.div`
  padding: 2rem;
  background: var(--color-white);
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% + 1rem);
  padding-right: 2rem;
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(var(--color-black), 0.4);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(var(--color-black), 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  scrollbar-width: thin;
  scrollbar-color: rgba(var(--color-black), 0.4) rgba(var(--color-black), 0.1);
`;

const MessagesSection = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
