import { PropsWithChildren } from 'react';
import { useProtectedAccess } from '../../hooks/useProtectedAccess';
import ForbiddenState from '@rio-cloud/rio-uikit/components/states/ForbiddenState';

const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const hasAccess = useProtectedAccess();

  if (!hasAccess) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          textAlign: 'center',
        }}
      >
        <ForbiddenState
          headline={<span>You don&apos;t have access to this application</span>}
          message={<span>This page is currently under construction and you do not have access.</span>}
        />
      </div>
    );
  }

  return children;
};

export default ProtectedRoute;
