import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';

const extractTableDataFromMarkdown = (markdownContent: string) => {
  const tableData: string[][] = [];

  const lines = markdownContent.split('\n');

  lines.forEach((line) => {
    if (line.includes('|')) {
      const row = line
        .split('|')
        .map((cell) => cell.trim().replace(/\*\*/g, ''))
        .map((cell) => cell.trim())
        .filter(Boolean);

      if (row.some((cell) => !/^[-]+$/.test(cell))) {
        tableData.push(row);
      }
    }
  });

  return tableData;
};

export const exportMarkdownTableToPDF = (markdownContent: string, fileName: string) => {
  const tableData = extractTableDataFromMarkdown(markdownContent);

  if (tableData.length > 0) {
    const headers = tableData[0];
    const rows = tableData.slice(1);

    const doc = new jsPDF();

    const timestamp = new Date().toLocaleString();
    doc.setFontSize(10);
    doc.text(`Generated on: ${timestamp}`, 14, 10);

    autoTable(doc, {
      head: [headers],
      body: rows,
      startY: 20,
      headStyles: {
        fillColor: [48, 180, 192],
        textColor: [255, 255, 255],
      },
      theme: 'striped',
    });

    doc.save(`${fileName}.pdf`);
  }
};
