import OverlayTrigger from '@rio-cloud/rio-uikit/components/overlay/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/components/tooltip/Tooltip';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { exportResponseToPDF } from '../../../utils/export/exportResponseToPDF/exportResponseToPDF';
import { selectCurrentService, Service } from '../../../store/service/serviceSlice';
import { Message } from '../../../store/chat/chat.slice';
import { Steps } from '../../../store/onboarding/onboardingSlice';
import { useSelector } from 'react-redux';

type ChatActionsProps = {
  question: string;
  message: Message;
  handleCopy: () => void;
  handleSend: (content: string, service: Service, isOnboarding: boolean, nextStepIndex?: Steps) => void;
};

export const ChatActions = ({ question, message, handleCopy, handleSend }: ChatActionsProps) => {
  const currentService = useSelector(selectCurrentService);

  const handleRegenerate = () => handleSend(question, currentService, false);

  const handleExportToPDF = () => exportResponseToPDF(message.content);

  const actionProps = [
    {
      icon: 'rioglyph-duplicate',
      tooltip: 'chatPage.chatActions.tooltip.copy.label',
      onClick: handleCopy,
    },
    {
      icon: 'rioglyph-pdf-file',
      tooltip: 'chatPage.chatActions.tooltip.exportToPDF.label',
      onClick: handleExportToPDF,
    },
    {
      icon: 'rioglyph-repeat',
      tooltip: 'chatPage.chatActions.tooltip.regenerate.label',
      onClick: handleRegenerate,
    },
  ];

  return (
    <MessageActionWrapper className="btn-toolbar">
      {actionProps.map((action) => (
        <ActionWrapper key={`wrapper-${crypto.randomUUID()}`}>
          <OverlayTrigger
            key={`overlay-${crypto.randomUUID()}`}
            overlay={
              <Tooltip id="tooltip" allowOnTouch key={`tooltip-${crypto.randomUUID()}`}>
                <FormattedMessage id={action.tooltip} />
              </Tooltip>
            }
          >
            <span className={`rioglyph ${action.icon}`} onClick={action.onClick} />
          </OverlayTrigger>
        </ActionWrapper>
      ))}
    </MessageActionWrapper>
  );
};

const MessageActionWrapper = styled.div`
  width: max-content;
`;

const ActionWrapper = styled.div`
  cursor: pointer;
  padding-top: 3px;
  font-size: 2rem;
  &:hover {
    color: var(--brand-primary);
  }
`;
