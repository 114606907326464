import styled from 'styled-components';
import { Fragment } from 'react/jsx-runtime';
import { FormattedMessage } from 'react-intl';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ToolCard from '../../molecule/ToolCard/ToolCard';
import { setCurrentPage } from '../../../store/about/aboutSlice';
import PageNavigation from '../PageNavigation/PageNavigation';
import { setCurrentLayout } from '../../../store/layout/layoutSlice';

const texts = {
  introduction: 'getStartedPage.tools.introduction',
  previousLabel: 'getStartedPage.howToWritePrompts.label',
  nextLabel: 'getStartedPage.common.startChat.label',
};

const cards = [
  {
    title: 'getStartedPage.common.performanceAnalysis.label',
    imageName: 'perform-analysis-new',
    description: 'getStartedPage.common.performanceAnalysis.description',
  },
  {
    title: 'getStartedPage.common.manTraining.label',
    imageName: 'man-training-new',
    description: 'getStartedPage.common.manTraining.description',
  },
];

const classes = {
  panel: 'panel panel-default panel-body panel-blank position-relative padding-x-25 margin-10',
  navigationPanel:
    'panel panel-default panel-body panel-blank position-relative margin-top-20 padding-x-25 width-100pct',
};

const Tools = () => {
  const dispatch = useDispatch();
  const handleGoToChat = () => dispatch(setCurrentLayout('chatHistory'));

  const pageTopRef = useRef<HTMLDivElement>(null);

  const handlePreviousSection = () => dispatch(setCurrentPage('page4'));

  useEffect(() => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Fragment>
      <div ref={pageTopRef} style={{ position: 'absolute', top: '0' }} />
      <div className={classes.panel}>
        <p>
          <FormattedMessage id={texts.introduction} />
        </p>
      </div>
      <GridContainer>
        {cards.map((card, index) => (
          <ToolCard key={index} title={card.title} imageName={card.imageName} description={card.description} />
        ))}
      </GridContainer>
      <div className={classes.navigationPanel}>
        <PageNavigation
          onNext={handleGoToChat}
          onPrevious={handlePreviousSection}
          previousLabel={texts.previousLabel}
          nextLabel={texts.nextLabel}
        />
      </div>
    </Fragment>
  );
};

export default Tools;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  padding: 0;
  margin-top: 25px;
`;
