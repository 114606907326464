import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import {
  activateOnboardingMode,
  disableDemoFlow,
  disableOnboardingMode,
  enableDemoFlow,
  resetStep,
  selectIsDemoFlowFinish,
  selectIsOnboardingMode,
} from '../../../store/onboarding/onboardingSlice';
import { addNewThread } from '../../../store/thread/threadSlice';
import { selectIsSidebarOpen } from '../../../store/sidebar/sidebarSlice';
import { useIntl } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { setShowTips } from '../../../store/tips/tipsSlice';
import { setCurrentLayout } from '../../../store/layout/layoutSlice';
import { setCurrentPage } from '../../../store/about/aboutSlice';

const texts = {
  stepOneTitle: 'chatPage.sidebar.onboardingMode.stepOne.title',
  stepOneText: 'chatPage.sidebar.onboardingMode.stepOne.text',
  stepOneButtonText: 'chatPage.sidebar.onboardingMode.stepOne.buttonText',
  stepOneAltButtonText: 'chatPage.sidebar.onboardingMode.stepOne.showTips.button',
  stepTwoTitle: 'chatPage.sidebar.onboardingMode.stepTwo.title',
  stepTwoText: 'chatPage.sidebar.onboardingMode.stepTwo.text',
  stepThreeTitle: 'chatPage.sidebar.onboardingMode.stepThree.title',
  stepThreeText: 'chatPage.sidebar.onboardingMode.stepThree.text',
  stepFourTitle: 'chatPage.sidebar.onboardingMode.stepFour.congrats.title',
  stepFourText: 'chatPage.sidebar.onboardingMode.stepFour.congrats.text',
  stepFourChatButton: 'chatPage.sidebar.onboardingMode.stepFour.chat.button',
  stepFourPromptButton: 'chatPage.sidebar.onboardingMode.stepFour.prompt.button',
  disableOnboarding: 'chatPage.sidebar.onboardingMode.disable',
  activateOnboarding: 'chatPage.sidebar.onboardingMode.activate',
  onboardingActiveLabel: 'chatPage.sidebar.onboardingMode.active.label',
  onboardingInactiveLabel: 'chatPage.sidebar.onboardingMode.inactive.label',
};

const OnboardingIntroductionContent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isOnboardingMode = useSelector(selectIsOnboardingMode);
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const isDemoFlowFinish = useSelector(selectIsDemoFlowFinish);

  const [step, setStep] = useState(0);
  const [messages, setMessages] = useState([]);
  const [isRobotVisible, setIsRobotVisible] = useState(false);
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  const addMessage = (newMessage) => {
    setMessages((prev) => {
      const alreadyExists = prev.some((msg) => msg.title === newMessage.title);
      if (!alreadyExists) {
        return [...prev, newMessage];
      }
      return prev;
    });
  };

  const steps = {
    0: {
      title: texts.stepOneTitle,
      text: texts.stepOneText,
      buttons: [
        {
          text: texts.stepOneButtonText,
          onClick: () => {
            dispatch(activateOnboardingMode());
            dispatch(enableDemoFlow());
            dispatch(resetStep());
            dispatch(addNewThread());
            setStep(1);
            addMessage(steps[1]);
          },
        },
        {
          text: texts.stepOneAltButtonText,
          onClick: () => {
            dispatch(setShowTips(true));
          },
        },
      ],
    },
    1: {
      title: texts.stepTwoTitle,
      text: texts.stepTwoText,
      buttons: [],
    },
    2: {
      title: texts.stepThreeTitle,
      text: texts.stepThreeText,
      buttons: [],
    },
    3: {
      title: texts.stepFourTitle,
      text: texts.stepFourText,
      buttons: [
        {
          text: texts.stepFourChatButton,
          onClick: () => {
            dispatch(disableDemoFlow());
            dispatch(disableOnboardingMode());
            dispatch(addNewThread());
            dispatch(setCurrentLayout('chatHistory'));
          },
        },
        {
          text: texts.stepFourPromptButton,
          onClick: () => {
            dispatch(setCurrentLayout('about'));
            dispatch(setCurrentPage('page4'));
          },
        },
      ],
    },
  };

  useEffect(() => {
    if (isSidebarOpen) {
      setTimeout(() => setIsRobotVisible(true), 700);

      setTimeout(() => {
        setMessages([steps[0]]);
        setStep(0);
      }, 1500);
    } else {
      setIsRobotVisible(false);
      setMessages([]);
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (isDemoFlowFinish) {
      setStep(3);
      addMessage(steps[3]);
    }
  }, [isDemoFlowFinish]);

  return (
    <Container>
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip width="auto">
            {intl.formatMessage({ id: isOnboardingMode ? texts.disableOnboarding : texts.activateOnboarding })}
          </Tooltip>
        }
      >
        <StyledButton
          className={`btn btn-outline ${isOnboardingMode ? 'btn-primary' : 'btn-muted'}`}
          onClick={() => {
            if (isOnboardingMode) {
              dispatch(disableOnboardingMode());
              setStep(0);
              setMessages([steps[0]]);
            } else {
              dispatch(activateOnboardingMode());
              setStep(1);
              addMessage(steps[0]);
              addMessage(steps[1]);
            }
          }}
        />
      </OverlayTrigger>
      <div>
        <ChatContainer>
          {messages.map((msg, index) => (
            <SpeechBubble key={index} delay={index * 0.5} isNew={index === messages.length - 1}>
              <Title>{intl.formatMessage({ id: msg.title })}</Title>
              <Text>{intl.formatMessage({ id: msg.text })}</Text>
              <ButtonContainer>
                {msg.buttons?.map((button, i) => (
                  <button
                    key={i}
                    type="button"
                    className="btn btn-outline btn-secondary btn-sm"
                    onClick={button.onClick}
                  >
                    {intl.formatMessage({ id: button.text })}
                  </button>
                ))}
              </ButtonContainer>
            </SpeechBubble>
          ))}
        </ChatContainer>
        {isRobotVisible && (
          <RobotContainer>
            <RobotImage src={'assets/images/robot.svg'} alt="" />
          </RobotContainer>
        )}
        <div ref={chatContainerRef} style={{ position: 'absolute', bottom: '0' }} />
      </div>
    </Container>
  );
};

export default OnboardingIntroductionContent;

const flyInAnimation = keyframes`
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const floatAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-height: 200px;
  height: 100%;
  width: fit-content;
  justify-content: flex-end;
  padding: 2rem;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  top: 20px;
  margin-left: 4rem;
  margin-right: 1rem;
  margin-bottom: 6rem;
  height: 100%;
`;

const SpeechBubble = styled.div<{ delay: number; isNew: boolean }>`
  background-color: var(--color-highlight-lightest);
  border-radius: 10px;
  padding: 1.5rem;
  position: relative;
  max-width: 100%;
  opacity: 0;
  transform: translateY(30px);
  animation: ${flyInAnimation} 0.8s ease-out forwards;
  animation-delay: ${(props) => props.delay}s;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
`;

const Text = styled.div`
  margin-top: 0.5rem;
  font-size: 1.1rem;
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 300px;
`;

const RobotContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
  animation:
    ${flyInAnimation} 1s ease-out forwards,
    ${floatAnimation} 3s infinite ease-in-out;
  animation-fill-mode: forwards;
  position: absolute;
  bottom: 10px;
  left: 10px;
`;

const RobotImage = styled.img`
  width: 50px;
  height: auto;
  z-index: 1020;
`;

const StyledButton = styled.button`
  border: none !important;
`;
