import { createSlice, createEntityAdapter, nanoid, type PayloadAction } from '@reduxjs/toolkit';

export type Feedback = {
  id: string;
  rate: number | undefined;
  detail?: string | undefined;
  lastQuestionId?: number | undefined | null;
  lastAnswerId?: number | undefined;
  userId: string;
};

const feedbackAdapter = createEntityAdapter<Feedback>({});

const initialState = feedbackAdapter.getInitialState();

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    addNewFeedback: {
      reducer: (state, action: PayloadAction<Feedback>) => {
        const existingFeedback = Object.values(state.entities).find(
          (feedback) => feedback?.lastQuestionId === action.payload.lastQuestionId,
        );

        if (existingFeedback) {
          feedbackAdapter.updateOne(state, {
            id: existingFeedback.id,
            changes: action.payload,
          });
        } else {
          feedbackAdapter.addOne(state, action.payload);
        }
      },
      prepare: (feedback: Omit<Feedback, 'id'>) => {
        const id = nanoid();
        return {
          payload: {
            ...feedback,
            id,
          },
          meta: { id },
        };
      },
    },
    updateFeedback: {
      reducer: (state, action: PayloadAction<Feedback>) => {
        feedbackAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      },
      prepare: (feedback: Feedback) => ({
        payload: feedback,
      }),
    },
    clearFeedback: feedbackAdapter.removeAll,
  },
});

export const { addNewFeedback, updateFeedback, clearFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;

export const {
  selectAll: selectAllFeedback,
  selectById: selectFeedbackById,
  selectIds: selectFeedbackIds,
} = feedbackAdapter.getSelectors((state: { feedback: typeof initialState }) => state.feedback);
