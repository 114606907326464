import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';

type ActionIconProps = {
  iconClass: string;
  onClick: MouseEventHandler<HTMLSpanElement>;
  tooltipText: string;
};

const ActionIcon = ({ onClick, iconClass, tooltipText }: ActionIconProps) => (
  <OverlayTrigger
    placement="top"
    overlay={
      <Tooltip>
        <FormattedMessage id={tooltipText} />
      </Tooltip>
    }
  >
    <span className={iconClass} onClick={onClick} />
  </OverlayTrigger>
);

export default ActionIcon;
