import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Page, PageProps, pageToContent } from './pageToContent';

export type AboutState = {
  currentPage: Page;
  currentPageProps: PageProps;
};

const loadStateFromLocalStorage = (): AboutState => {
  try {
    const savedState = localStorage.getItem('aboutState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      return {
        currentPage: parsedState.currentPage,
        currentPageProps: pageToContent[parsedState.currentPage],
      };
    }
  } catch (e) {
    console.error('Failed to load state from localStorage:', e);
  }
  return {
    currentPage: 'page1',
    currentPageProps: pageToContent['page1'],
  };
};

const initialState: AboutState = loadStateFromLocalStorage();

const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<Page>) => {
      state.currentPage = action.payload;
      state.currentPageProps = pageToContent[action.payload];

      localStorage.setItem('aboutState', JSON.stringify({ currentPage: state.currentPage }));
    },
  },
});

export const { setCurrentPage } = aboutSlice.actions;

export default aboutSlice.reducer;

export const selectCurrentPage = (state: { about: AboutState }) => state.about.currentPage;

export const selectCurrentPageProps = (state: { about: AboutState }) => state.about.currentPageProps;
