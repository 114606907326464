import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';
import { MessageActionBar } from '../MessageActionBar/MessageActionBar';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsLoadingMessage, selectMessageById } from '../../../store/chat/chat.slice';
import { FormattedMessage, useIntl } from 'react-intl';
import { selectCurrentService, Service } from '../../../store/service/serviceSlice';
import {
  resetStep,
  selectIsOnboardingMode,
  selectOnboardingMessageId,
  selectOnboardingStep,
  selectShowOnboardingTip,
  Steps,
  steps,
  showOnboardingTip,
  finishDemoFlow,
  selectIsDemoFlowEnabled,
  disableDemoFlow,
} from '../../../store/onboarding/onboardingSlice';
import { getLocale } from '../../../configuration/lang/langSlice';
import { markdownRenderers } from '../../../utils/markdownRenderers/markdownRenderers';
import { Fragment, useEffect, useRef, useState } from 'react';
import OverlayTrigger from '@rio-cloud/rio-uikit/components/overlay/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/components/tooltip/Tooltip';
import { onboardingMessagesDE, onboardingMessagesEN } from '../../../../mocks/mock.onboarding';
import OnboardingDraggableTip from '../OnboardingDraggableTip/OnboardingDraggableTip';

export type SpeechBubbleProps = {
  messageId: number;
  handleSend: (content: string, service: Service, isOnboarding: boolean, nextStepIndex?: Steps) => void;
};

type StepToService = {
  [key in Steps]: Service;
};

const stepToService: StepToService = {
  'step-0': 'perform',
  'step-1': 'perform',
  'step-2': 'training',
  'step-3': 'perform',
  'step-4': 'perform',
};

const texts = {
  titles: {
    wellDone: 'chatPage.onboarding.wellDone',
    onboardingComplete: 'chatPage.onboarding.onboardingCompleteTitle',
  },
  contents: {
    tryNextQuestion: 'chatPage.onboarding.tryNextQuestion',
    onboardingComplete: 'chatPage.onboarding.onboardingCompleteContent',
  },
  buttons: {
    skip: 'chatPage.onboarding.buttonSkip',
    tryNextQuestion: 'chatPage.onboarding.buttonTryNextQuestion',
    okThanks: 'chatPage.onboarding.buttonOkThanks',
  },
  icons: {
    skipIcon: 'rioglyph-eye-close',
    sendIcon: 'rioglyph-send',
    repeat: 'rioglyph-repeat',
  },
  editTooltip: 'chatPage.SpeechBubble.edit.tooltip',
  sendButton: 'chatPage.SpeechBubble.edit.button.send',
  cancelButton: 'chatPage.SpeechBubble.edit.button.cancel',
  roleLabels: {
    ai: 'chatPage.SpeechBubble.role.ai.label',
    you: 'chatPage.SpeechBubble.role.you.label',
  },
};

const SpeechBubble = ({ messageId, handleSend }: SpeechBubbleProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { content, role, isOnboardingQuestion } = useSelector(selectMessageById(messageId));
  const onboardingStep = useSelector(selectOnboardingStep);
  const isOnboardingMode = useSelector(selectIsOnboardingMode);
  const isOnboardingTipShow = useSelector(selectShowOnboardingTip);
  const isOnboardingDemoEnabled = useSelector(selectIsDemoFlowEnabled);
  const onboardingMessageId = useSelector(selectOnboardingMessageId);
  const currentLanguage = useSelector(getLocale);
  const currentService = useSelector(selectCurrentService);
  const isLoading = useSelector(selectIsLoadingMessage);

  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const questions = currentLanguage === 'de-DE' ? onboardingMessagesDE : onboardingMessagesEN;

  const getNextQuestion = (stepIndex) => {
    const nextStep = steps[stepIndex];
    const stepMessages = questions[nextStep]?.messages?.content;

    const humanQuestions = stepMessages?.filter((message) => message.role === 'human');
    return humanQuestions?.length > 0 ? humanQuestions[humanQuestions.length - 1].content : '';
  };

  const handleNextStep = () => {
    const currentStepIndex = onboardingStep !== undefined ? steps.indexOf(onboardingStep) : -1;
    const nextStepIndex = currentStepIndex + 1 < steps.length ? currentStepIndex + 1 : 0;
    const nextStep = steps[nextStepIndex];

    dispatch({ type: 'onboarding/setStep', payload: nextStep });

    const nextQuestion = getNextQuestion(nextStepIndex);

    if (onboardingStep === 'step-4') {
      dispatch(resetStep());
    } else {
      handleSend(nextQuestion, stepToService[nextStep], true, nextStep);
    }
  };

  const handleOnHide = () => {
    if (onboardingStep === 'step-4') {
      dispatch(finishDemoFlow());
      dispatch(disableDemoFlow());
    }
  };

  const handleEditClick = () => setIsEditing(true);

  const handleEditSubmit = () => {
    setIsEditing(false);
    handleSend(editedContent, currentService, false);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
    setEditedContent(content);
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (isEditing) {
      adjustTextareaHeight();
    }
  }, [isEditing, editedContent]);

  const fileContent = (
    <TextMessage>
      {isEditing ? (
        <div className="form-group width-100pct display-flex flex-column">
          <textarea
            ref={textareaRef}
            className="form-control"
            id="textArea"
            value={editedContent}
            onBlur={handleEditSubmit}
            onChange={(e) => setEditedContent(e.target.value)}
          />
          <div className="display-flex gap-10 justify-content-end margin-top-10">
            <button className="btn btn-outline btn-secondary" onClick={handleEditCancel}>
              <FormattedMessage id={texts.cancelButton} />
            </button>
            <button className="btn btn-primary" onClick={handleEditSubmit}>
              <FormattedMessage id={texts.sendButton} />
            </button>
          </div>
        </div>
      ) : (
        <Fragment>
          <OnboardingDraggableTip
            show={
              !isLoading &&
              isOnboardingQuestion &&
              isOnboardingMode &&
              isOnboardingTipShow &&
              isOnboardingDemoEnabled &&
              messageId === onboardingMessageId
            }
            title={
              onboardingStep !== 'step-4'
                ? intl.formatMessage({ id: texts.titles.wellDone })
                : intl.formatMessage({ id: texts.titles.onboardingComplete })
            }
            content={
              onboardingStep !== 'step-4'
                ? intl.formatMessage({ id: texts.contents.tryNextQuestion })
                : intl.formatMessage({ id: texts.contents.onboardingComplete })
            }
            nextButton={{
              iconName: onboardingStep !== 'step-4' ? texts.icons.sendIcon : '',
              text:
                onboardingStep !== 'step-4'
                  ? intl.formatMessage({ id: texts.buttons.tryNextQuestion })
                  : intl.formatMessage({ id: texts.buttons.okThanks }),
              onClick: onboardingStep !== 'step-4' ? handleNextStep : handleOnHide,
            }}
          />
          <ReactMarkdown components={markdownRenderers(content)} remarkPlugins={[remarkGfm]}>
            {content}
          </ReactMarkdown>
        </Fragment>
      )}
    </TextMessage>
  );

  useEffect(() => {
    if (isOnboardingQuestion && messageId) {
      dispatch(showOnboardingTip({ messageId }));
    }
  }, [isOnboardingQuestion, messageId]);

  return (
    <BubbleWrapper role={role}>
      {role === 'ai' && (
        <div className="text-color-darkest text-size-14 text-medium margin-bottom-5 gap-5 align-items-center">
          <span className="rioglyph rioglyph-robot text-size-16 margin-right-5" aria-hidden="true" />
          <span>FleetAssistant</span>
        </div>
      )}
      <HoverArea>
        {role === 'human' && !isEditing && (
          <OverlayTrigger
            key={'edit-tooltip'}
            placement={'auto-start'}
            overlay={
              <Tooltip id="tooltip-edit" allowOnTouch>
                <FormattedMessage id={texts.editTooltip} />
              </Tooltip>
            }
          >
            <EditIcon onClick={handleEditClick} data-testid="icon-edit" className="rioglyph rioglyph-pencil" />
          </OverlayTrigger>
        )}
        <Bubble role={role} data-testid={`speech_bubble-${role}`}>
          {fileContent}
          {role === 'ai' ? <MessageActionBar messageId={messageId} handleSend={handleSend} /> : null}
        </Bubble>
      </HoverArea>
    </BubbleWrapper>
  );
};

export default SpeechBubble;

const EditIcon = styled.span`
  border-radius: 50%;
  padding: 0.7rem;
  background: var(--gray-lightest);
  margin-left: 0.5rem;
  cursor: pointer;
  font-size: 1.3rem;
  position: absolute;
  top: 15px;
  left: -40px;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;

  &:hover {
    opacity: 1;
    visibility: visible;
  }
`;

const HoverArea = styled.div`
  position: relative;
  display: flex;
  justify-content: ${({ role }) => (role === 'human' ? 'flex-end' : 'flex-start')};

  &:hover ${EditIcon} {
    opacity: 1;
    visibility: visible;
  }
`;

const BubbleWrapper = styled.div<{ role: 'human' | 'ai' }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ role }) => (role === 'human' ? 'flex-end' : 'flex-start')} !important;
  align-items: ${({ role }) => (role === 'human' ? 'end' : 'start')} !important;
  margin: 0.8rem 0;
  padding-left: ${({ role }) => (role === 'human' ? '4rem' : 0)} !important;
  width: 100%;
  position: relative;
`;

const Bubble = styled.div<{ role: 'human' | 'ai' }>`
  background: ${({ role }) => (role === 'human' ? 'var(--gray-lightest)' : 'var(--color-highlight-lightest)')};
  color: var(--gray-darkest);
  padding: 1rem 2rem;
  margin-top: ${({ role }) => (role === 'human' ? '1rem' : 0)};
  word-wrap: break-word;
  border-radius: 6px;
  position: relative;
  min-width: 400px;
`;

const TextMessage = styled.div`
  word-wrap: break-word;
`;
