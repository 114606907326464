const routeFeature = ['homepage', 'getStarted', 'feedback', 'iframeTest'] as const;
const routesUrl = ['/', '/get-started', '/feedback', '/iframe-test'] as const;

type RouteFeature = (typeof routeFeature)[number];
export type RoutesUrl = (typeof routesUrl)[number];

type Getters<T extends string, K> = {
  [k in T]: K;
};

type RoutesData = Getters<RouteFeature, RoutesUrl>;

const routes: RoutesData = {
  homepage: '/',
  getStarted: '/get-started',
  feedback: '/feedback',
  iframeTest: '/iframe-test',
} as const;

export default routes;
