import Draggable from 'react-draggable';
import styled, { keyframes } from 'styled-components';

type OnboardingButton = {
  text: string;
  iconName: string;
  onClick: () => void;
};

type OnboardingDraggableTipProps = {
  show: boolean;
  title: string;
  content: string;
  previousButton?: OnboardingButton;
  nextButton?: OnboardingButton;
};

const OnboardingDraggableTip = ({ show, title, content, previousButton, nextButton }: OnboardingDraggableTipProps) => {
  if (!show) return null;

  return (
    <Draggable>
      <Card>
        <p className="text-size-18 margin-0">{title}</p>
        <div>{content}</div>
        <ButtonContainer>
          {previousButton ? (
            <StyledButton
              type="button"
              className="btn btn-secondary hover border-none"
              onClick={previousButton.onClick}
            >
              <span className={`rioglyph ${previousButton.iconName}`} aria-hidden="true" />
              {previousButton.text}
            </StyledButton>
          ) : null}
          {nextButton ? (
            <StyledButton type="button" className="btn btn-secondary border-color-white" onClick={nextButton.onClick}>
              {nextButton.text}
              <span className={`rioglyph ${nextButton.iconName}`} aria-hidden="true" />
            </StyledButton>
          ) : null}
        </ButtonContainer>
      </Card>
    </Draggable>
  );
};

export default OnboardingDraggableTip;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Card = styled.div`
  background-color: var(--brand-secondary);
  color: white;
  padding: 1.2rem;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  cursor: grab;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
`;
