import ExpanderPanel from '@rio-cloud/rio-uikit/components/expander/ExpanderPanel';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const textContent = {
  partOne: 'getStartedPage.expanderPanelWarning.description.partOne',
  partTwo: 'getStartedPage.expanderPanelWarning.description.partTwo',
  partThree: 'getStartedPage.expanderPanelWarning.description.partThree',
  partFour: 'getStartedPage.expanderPanelWarning.description.partFour',
  partFive: 'getStartedPage.expanderPanelWarning.description.partFive',
  partSix: 'getStartedPage.expanderPanelWarning.description.partSix',
  safety_notice: {
    title: 'getStartedPage.expanderPanelWarning.safetyNotice.title',
    info: 'getStartedPage.expanderPanelWarning.safetyNotice.info',
    highlighted_info: 'getStartedPage.expanderPanelWarning.safetyNotice.highlighted_info',
  },
};

const ExpanderPanelWarning = () => {
  return (
    <Container>
      <div className="margin-bottom-25">
        <p className="text-color-darker margin-bottom-0">
          <FormattedMessage id={textContent.partOne} />
        </p>
        <p className="text-color-darker margin-bottom-0">
          <FormattedMessage id={textContent.partTwo} />
          <span className="text-bold">
            <FormattedMessage id={textContent.partThree} />
          </span>
          <FormattedMessage id={textContent.partFour} />
          <span className="text-bold">
            <FormattedMessage id={textContent.partFive} />
          </span>
          <FormattedMessage id={textContent.partSix} />
        </p>
      </div>
      <ExpanderPanel
        className="alert alert-dismissible alert-warning padding-0"
        open
        title={<FormattedMessage id={textContent.safety_notice.title} />}
        bsStyle="warning"
      >
        <div className="display-flex gap-10">
          <span className="text-color-warning text-size-h4 rioglyph rioglyph-exclamation-sign" />
          <div>
            <p hidden={textContent.safety_notice.info === ''} className="margin-0">
              <FormattedMessage id={textContent.safety_notice.info} />
            </p>
            <p
              hidden={textContent.safety_notice.highlighted_info === ''}
              className="text-medium text-uppercase text-size-12 text-color-warning margin-bottom-0"
            >
              <FormattedMessage id={textContent.safety_notice.highlighted_info} />
            </p>
          </div>
        </div>
      </ExpanderPanel>
    </Container>
  );
};

export default ExpanderPanelWarning;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem 0 2rem;
  margin-bottom: 1rem;
`;
